import React from "react";
import ReactApexChart from "react-apexcharts";

function LineChart({ data }) {
  function getLast4Days() {
    const today = new Date();
    const categories = [];

    for (let i = 0; i < 4; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      const formattedDate = `${day.getMonth() + 1}/${day.getDate()}`;
      categories.unshift(formattedDate);
    }

    return categories;
  }
  const last4DaysCategories = getLast4Days();

  const chartData = {
    series: [
      {
        name: data?.MetalName,
        data: [data?.value1, data?.value2, data?.value3, data?.value4],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Market Inteligence Chart",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: last4DaysCategories,
      },
      yaxis: {
        opposite: true
      }
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        width="500"
        type="area" 
      />
    </div>
  );
}

export default LineChart;
