import { useState } from "react";
import { Grid } from "@mui/material";

import {
  Container,
  Header,
  LogoIcon,
  Content,
  PersonalData,
  Data,
  SectionContainer,
  FormGroups,
  ActionsContainer,
} from "./styles";

import Drawer from "../../components/Drawer";
import LoadingPage from "../../components/LoadingPage";

import logo from "../../icons/T-SaleMetals-03.png";

import useProfile from "./useProfile";
import {
  FloatInput,
  FloatSelect,
} from "../../components/FloatInput/FloatInput";

export default function Profile() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    isLoading,
    errors,

    name,
    lastName,
    email,
    phone,
    role,

    actualPassword,
    newPassword,
    confirmNewPassword,

    city,
    country,
    line1,
    postalCode,
    state,

    personalInfoChanged,
    handleChangeName,
    handleChangeLastName,
    handleChangePhone,
    handleChangeEmail,
    handleChangeRole,
    handleCancelPersonal,
    handleConfirmPersonal,

    isChangingPassword,
    handleActualPassword,
    handleNewPassword,
    handleConfirmPassword,
    handleCancelChangePassword,
    handleChangePassword,

    AddressChanged,
    handleChangeCity,
    handleChangeCountry,
    handleChangeLine1,
    handleChangePostalCode,
    handleChangeState,
    handleCancelAddress,
    handleChangeAddress,
  } = useProfile();

  function SetToggle(state) {
    setIsDrawerOpen(state);
  }

  function getErrorMessageByFieldName(fieldName) {
    return errors.find((error) => error.field === fieldName)?.message;
  }

  return (
    <Container>
      {isLoading && <LoadingPage />}

      {!isLoading && (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={isDrawerOpen ? 2 : 1}>
            <Drawer handleToggle={SetToggle} initState={isDrawerOpen} />
          </Grid>

          <Grid item xs={isDrawerOpen ? 10 : 11} container alignItems="center">
            <Content>
              <Header>
                <h1>Profile</h1>

                <LogoIcon src={logo} alt="T-sale Logo" />
              </Header>

              <SectionContainer>
                <FormGroups>
                  <PersonalData>
                    <header>
                      <h2>Personal Data</h2>

                      <span>These datas are used for identification</span>
                    </header>

                    <Data style={{ marginLeft: 48 }}>
                      <FloatInput
                        value={name}
                        onChange={handleChangeName}
                        label={"First Name"}
                        error={getErrorMessageByFieldName("name")}
                      />

                      <FloatInput
                        value={lastName}
                        onChange={handleChangeLastName}
                        label={"Last Name"}
                        error={getErrorMessageByFieldName("lastName")}
                      />

                      <FloatInput
                        value={phone}
                        onChange={handleChangePhone}
                        label={"Phone"}
                        error={getErrorMessageByFieldName("phone")}
                      />

                      <FloatInput
                        readOnly
                        value={email}
                        onChange={handleChangeEmail}
                        label={"E-mail"}
                        error={getErrorMessageByFieldName("email")}
                      />

                      <FloatSelect
                        value={role}
                        onChange={handleChangeRole}
                        label={role}
                        defaultValue={role}
                      >
                        <option value={"Commercial"}>Commercial</option>
                        <option value={"Purchasing"}>Purchasing</option>
                        <option value={"Marketing"}>Marketing</option>
                        <option value={"Directorship"}>Directorship</option>
                        <option value={"Management"}>Management</option>
                      </FloatSelect>

                      {personalInfoChanged && (
                        <ActionsContainer>
                          <button
                            className="save"
                            type="button"
                            onClick={handleConfirmPersonal}
                          >
                            Save
                          </button>
                          <button
                            className="cancel"
                            type="button"
                            onClick={handleCancelPersonal}
                          >
                            Cancel
                          </button>
                        </ActionsContainer>
                      )}
                    </Data>
                  </PersonalData>

                  <PersonalData>
                    <header>
                      <h2>Password</h2>

                      <span>
                        To change your password, confirm your actual password
                        and then insert the new one
                      </span>
                    </header>

                    <Data style={{ marginLeft: 48 }}>
                      <FloatInput
                        type="password"
                        onChange={handleActualPassword}
                        value={actualPassword}
                        label={"Actual password"}
                        notRequired
                      />
                      <FloatInput
                        type="password"
                        onChange={handleNewPassword}
                        value={newPassword}
                        label={"New password"}
                        notRequired
                      />

                      <FloatInput
                        type="password"
                        onChange={handleConfirmPassword}
                        value={confirmNewPassword}
                        label={"Confirm the new password"}
                        notRequired
                      />

                      {isChangingPassword && (
                        <ActionsContainer>
                          <button
                            className="save"
                            type="button"
                            onClick={handleChangePassword}
                          >
                            Change
                          </button>
                          <button
                            className="cancel"
                            type="button"
                            onClick={handleCancelChangePassword}
                          >
                            Cancel
                          </button>
                        </ActionsContainer>
                      )}
                    </Data>
                  </PersonalData>
                </FormGroups>

                <FormGroups>
                  <PersonalData>
                    <header style={{ marginLeft: 48 }}>
                      <h2>Address</h2>

                      <span>
                        This address is used on the confirmation of a purchase
                      </span>
                    </header>

                    <Data>
                      <FloatInput
                        value={city}
                        onChange={handleChangeCity}
                        label={"City"}
                        error={getErrorMessageByFieldName("city")}
                      />

                      <FloatInput
                        value={country}
                        onChange={handleChangeCountry}
                        label={"Country"}
                        error={getErrorMessageByFieldName("country")}
                      />

                      <FloatInput
                        value={line1}
                        onChange={handleChangeLine1}
                        label={"Line 1"}
                        error={getErrorMessageByFieldName("line1")}
                      />

                      <FloatInput
                        value={postalCode}
                        onChange={handleChangePostalCode}
                        label={"Postal Code"}
                        error={getErrorMessageByFieldName("postalCode")}
                      />

                      <FloatInput
                        value={state}
                        onChange={handleChangeState}
                        label={"State"}
                        error={getErrorMessageByFieldName("state")}
                      />

                      {AddressChanged && (
                        <ActionsContainer>
                          <button
                            className="save"
                            type="button"
                            onClick={handleChangeAddress}
                          >
                            Save
                          </button>
                          <button
                            className="cancel"
                            type="button"
                            onClick={handleCancelAddress}
                          >
                            Cancel
                          </button>
                        </ActionsContainer>
                      )}
                    </Data>
                  </PersonalData>
                </FormGroups>
              </SectionContainer>
            </Content>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
