import React, { useEffect, useState } from "react";
import { Grid, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import format from "date-fns/format";
import AddIcon from "@mui/icons-material/Add";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";

import { ChartsHome } from "./graph";

import ServiceMenuSimulator from "../../icons/port.png";
import ServiceMenuMetalPrice from "../../icons/MetalPrice1.png";
import ServiceMenuBlockchain from "../../icons/SustainabilityIsometricWhite 1.png";
import ServiceMenuSustainability from "../../icons/sustainIcon.png";
import SponsorIcon from "../../icons/SponsorImg.svg";

import { authScreen } from "../../contexts/auth";

import CompanyMapperInfo from "./utils/mapper";

import {
  ColumnContainer,
  ContainerHome,
  ImgDefault,
  PanelTrade,
  RowContainer,
  TagVariation,
  TextDefault,
} from "../../assets/styles";

import Carousel from "../../components/Carousel";
import ResponsiveMapping from "../../components/ResponsiveMapping";
import LoadingPage from "../../components/LoadingPage";
import Drawer from "../../components/Drawer";
import Partner from "./components/Partner";
import NewsCard from "./components/NewsCard";

import { getPromotions } from "../../hooks/notifications";
import getUserInfo from "../../hooks/getUsers";
import { getCompanieFavorite } from "../../hooks/findNewPartner";
import { useNews } from "../../hooks/getNews";
import { getFavoriteMetals, deleteFavoriteMetal } from "../../hooks/metalPrice";
import { SliderNavigation } from "./components/SliderNavigation";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import {
  CardServiceMinimal,
  Container,
  DeleteMetalButton,
  FavoriteCompanies,
  FavoriteCompaniesContainer,
  FavoriteMetals,
  Header,
  NewsContainer,
  NotificationBtn,
  Notifications,
  Service,
  ServicesContainer,
  SponsorContainer,
} from "./styles";
import "swiper/css";
import SearchInput from "./components/SearchInput";
import { saveAnalytics } from "../../hooks/analytics";

const { size } = ResponsiveMapping();

export default function Home() {
  const gData1 = [1398, 1398, 7800, 4508, 8900];
  const gData2 = [8900, 4508, 7800, 1398, 1398];
  const [auth, setAuth] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingg, setLoadingg] = useState(true);
  const [companies, setCompanies] = useState("");
  const [toggleDrawer, useTroggleDawer] = useState(true);
  const [favoriteMetals, setFavoriteMetals] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [search, setSearch] = useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const [sliderState, setSliderState] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const [promotions, setPromotions] = useState([]);

  const { newsData, loading } = useNews();
  const windowWidth = useWindowWidth();

  const navigate = useNavigate();
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 4000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  useEffect(() => {
    if (!loading) {
      const newsArray = newsData ? Object.values(newsData) : [];
      setAllNews(newsArray);
    }
  }, [loading, newsData]);

  // promocoes
  useEffect(() => {
    getPromotions()
      .then((dados) => {
        const promotionsArray = Object.values(dados);
        setPromotions(promotionsArray);
      })
      .catch((error) => {
        console.error("Erro ao verificar os dados de promotions:", error);
      });

    getFavoriteMetals(userInfo?.uid)
      .then((res) => {
        const favMetals = Object.keys(res).map((key) => res[key].data);

        setFavoriteMetals(favMetals);
      })
      .catch((err) => console.log("Error to fetch metals:", err));
  }, [userInfo]);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();          
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoadingg(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      const fetchDataC = async () => {
        try {
          const dataUnmapped = await getCompanieFavorite(userInfo.uid);
          const mappedData = CompanyMapperInfo(dataUnmapped);

          console.log(dataUnmapped);
          setCompanies(mappedData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        } finally {
          setLoadingg(false);
        }
      };

      fetchDataC();
    }
  }, [auth, userInfo]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  function cleanMetalName(metal) {
    const metalName = metal.split(" ");

    return metalName.length > 0 ? metalName[0] : "";
  }

  function convertStringToNumber(number) {
    return parseFloat(number?.replace(",", ".")).toFixed(2);
  }

  function CalculatePercentage(firstNumber, finalNumber) {
    const convertedfirst = convertStringToNumber(firstNumber);
    const convertedFinal = convertStringToNumber(finalNumber);

    const percentageVariation =
      ((convertedFinal - convertedfirst) / convertedfirst) * 100;

    return `${percentageVariation.toFixed(2)}%`;
  }

  async function handleDeleteMetal(id) {
    console.log({ id, userId: userInfo?.uid });
    await deleteFavoriteMetal(id, userInfo?.uid);

    getFavoriteMetals(userInfo?.uid)
      .then((res) => {
        const favMetals = Object.keys(res).map((key) => res[key].data);

        setFavoriteMetals(favMetals);
      })
      .catch((err) => console.log(err));
  }

  const formattedDate = new Date().toISOString();

  const infoM = {
    action: `Open Page`,
    date: formattedDate,
    page: "Home",
    keywords: `Market Inteligence`,
    name: userInfo?.userData?.name
  };

  const infoS = {
    action: `Open Page`,
    date: formattedDate,
    page: "Home",
    keywords: `Simulation`,
    name: userInfo?.userData?.name
  };

  const infoSustain = {
    action: `Open Page`,
    date: formattedDate,
    page: "Home",
    keywords: `Sustain`,
    name: userInfo?.userData?.name
  };

  function OpenSimulation() {
    saveAnalytics(userInfo.uid, infoS);
    navigate("/simulation");
  }
  function OpenSustain() {
    saveAnalytics(userInfo.uid, infoSustain);
    navigate("/sustainability");
  }
  function OpenMarket() {
    saveAnalytics(userInfo.uid, infoM);
    navigate("/marketinteligence");
  }

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid container rowSpacing={1} xs={12} sm={12} md={12} lg={12}>
            <Grid
              item
              xs={toggleDrawer ? 2 : 1}
              sm={toggleDrawer ? 2 : 1}
              md={toggleDrawer ? 3 : 1}
              lg={toggleDrawer ? 2 : 1}
              style={{ zIndex: 2 }}
            >
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <Grid
              item
              xs={toggleDrawer ? 10 : 11}
              sm={toggleDrawer ? 10 : 11}
              md={toggleDrawer ? 8.6 : 11}
              lg={toggleDrawer ? 9.7 : 11}
              style={{
                height: windowWidth >= 1100 && "100dvh",
                paddingRight: 16,
              }}
            >
              <Container>
                <SearchInput
                  value={search}
                  isDrawerOpen={toggleDrawer}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <Notifications>
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClose}
                    message={promotions || "no news"}
                    key={vertical + horizontal}
                    autoHideDuration={3500}
                    sx={{
                      marginTop: 6,
                      marginRight: 6,
                    }}
                  />
                  <NotificationBtn
                    className="btnNotification"
                    onClick={handleClick({
                      vertical: "top",
                      horizontal: "right",
                    })}
                  >
                    <svg viewBox="0 0 448 512" class="bell">
                      <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path>
                    </svg>
                  </NotificationBtn>
                </Notifications>

                <Carousel userId={userInfo?.uid} name={userInfo?.userData?.name} />

                <FavoriteMetals>
                  <Swiper
                    slidesPerView={windowWidth > 500 ? 2 : 1}
                    spaceBetween={16}
                    style={{ padding: 16, width: "100%" }}
                    onSlideChange={(swiper) => {
                      setSliderState({
                        isBeginning: swiper.isBeginning,
                        isEnd: swiper.isEnd,
                      });
                    }}
                  >
                    <header
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      slot="container-start"
                    >
                      <SliderNavigation
                        isBeginning={sliderState.isBeginning}
                        isEnd={sliderState.isEnd}
                      />
                    </header>

                    {favoriteMetals.length === 0 && (
                      <SwiperSlide>
                        <PanelTrade
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "cursor",
                          }}
                          onClick={() =>
                            navigate("/marketinteligence-dashboard")
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 8,
                              border: "1px dashed #006DFF",
                              borderRadius: "100%",
                            }}
                          >
                            <AddIcon sx={{ color: "#006DFF" }} />
                          </div>

                          <h2 style={{ color: "#006DFF" }}>
                            Save your first metal
                          </h2>
                        </PanelTrade>
                      </SwiperSlide>
                    )}

                    {favoriteMetals.length > 0 && (
                      <>
                        {favoriteMetals.map((metal) => (
                          <SwiperSlide key={metal.id}>
                            <PanelTrade style={{ width: "100%" }}>
                              <RowContainer>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    maxHeight: "160px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <TextDefault
                                      color={"#4b4b4b"}
                                      size={"19px"}
                                    >
                                      {cleanMetalName(metal.MetalName)} Daily
                                      Variation
                                    </TextDefault>
                                    <br />
                                    <TextDefault
                                      color={"#8a97aa"}
                                      size={"12px"}
                                    >
                                      Quotation in Dollars
                                    </TextDefault>
                                  </div>

                                  <div>
                                    <TextDefault
                                      color={"#4D6484"}
                                      size={"20px"}
                                    >
                                      {parseFloat(
                                        metal.value4?.replace(",", ".")
                                      ).toFixed(2)}{" "}
                                      US$
                                    </TextDefault>
                                    <br />
                                    <TextDefault
                                      color={"#8a97aa"}
                                      size={"12px"}
                                    >
                                      {format(new Date(), "MM/dd/yyyy")}
                                    </TextDefault>
                                  </div>
                                  <TagVariation
                                    color={
                                      convertStringToNumber(metal.value3) <
                                      convertStringToNumber(metal.value4)
                                        ? "#4abc96"
                                        : "#fd3c4f"
                                    }
                                  >
                                    <TextDefault color={"#fff"} size={"13px"}>
                                      {CalculatePercentage(
                                        metal.value1,
                                        metal.value4
                                      )}
                                    </TextDefault>
                                  </TagVariation>
                                </div>
                                <ChartsHome
                                  data={gData1}
                                  metal={metal}
                                  width={size !== "lg" ? 350 : 250}
                                  height={240}
                                  style={{
                                    width: "70%",
                                    height: 240,
                                    right: 20,
                                    top: 50,
                                  }}
                                />

                                <DeleteMetalButton
                                  onClick={() => handleDeleteMetal(metal.id)}
                                >
                                  <BookmarkRemoveIcon
                                    sx={{ color: "#4d01a6" }}
                                  />
                                </DeleteMetalButton>
                              </RowContainer>
                            </PanelTrade>
                          </SwiperSlide>
                        ))}
                        <SwiperSlide>
                          <PanelTrade
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate("/marketinteligence-dashboard")
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 8,
                                border: "1px dashed #4d01a6",
                                borderRadius: "100%",
                              }}
                            >
                              <AddIcon sx={{ color: "#4d01a6" }} />
                            </div>

                            <h2 style={{ color: "#4d01a6" }}>
                              Save more metals
                            </h2>
                          </PanelTrade>
                        </SwiperSlide>
                      </>
                    )}
                  </Swiper>
                </FavoriteMetals>

                <NewsContainer>
                  <Swiper
                    spaceBetween={24}
                    slidesPerGroup={2}
                    slidesPerView={2}
                    loop={true}
                    centeredSlides={false}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    style={{ padding: 16 }}
                    modules={[Autoplay]}
                  >
                    {allNews.map((news, index) => (
                      <SwiperSlide key={index}>
                        <NewsCard item={news} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </NewsContainer>

                <ServicesContainer>
                  <Service>
                    <CardServiceMinimal  className="cardServices"  onClick={OpenSimulation}>
                      <ColumnContainer style={{textAlign: 'left', padding: 30}}>
                        <TextDefault size="20px" color="#4b4b4b">Simulatoin</TextDefault>
                        <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 15, width: '70%'}}>Tool will help your company to carry out metals commercialization Worldwide</TextDefault>
                        <TextDefault size="16px" color="#4d01a6" style={{marginTop: 20}}>Know more</TextDefault>
                      </ColumnContainer>
                      <RowContainer style={{position: 'absolute', width: 150}}>
                        <ImgDefault src={ServiceMenuSimulator} style={{marginLeft: 100}}/>
                      </RowContainer>
                    </CardServiceMinimal>
                  </Service>

                  <Service>
                  <CardServiceMinimal className="cardServices" onClick={OpenMarket}>
                      <ColumnContainer style={{textAlign: 'left', padding: 30}}>
                        <TextDefault size="20px" color="#4b4b4b">Market Inteligence</TextDefault>
                        <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 15, width: '70%'}}>On this page you can search for any metal and view the prices of the last three days</TextDefault>
                        <TextDefault size="16px" color="#4d01a6" style={{marginTop: 20}}>Know more</TextDefault>
                      </ColumnContainer>
                      <RowContainer style={{position: 'absolute', width: 120}}>
                        <ImgDefault src={ServiceMenuMetalPrice} style={{marginLeft: 100}}/>
                      </RowContainer>
                    </CardServiceMinimal>
                  </Service>

                  <Service>
                    <CardServiceMinimal className="cardServices">
                      <ColumnContainer style={{textAlign: 'left', padding: 30}}>
                        <TextDefault size="20px" color="#4b4b4b">Sustainability</TextDefault>
                        <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 15, width: '70%'}}>Fill out this form to receive validation of your company's environmental.</TextDefault>
                        <TextDefault size="16px" color="#4d01a6" style={{marginTop: 20}}>Coming Soon</TextDefault>
                      </ColumnContainer>
                      <RowContainer style={{position: 'absolute', width: 140}}>
                        <ImgDefault src={ServiceMenuSustainability} style={{marginLeft: 100}}/>
                      </RowContainer>
                    </CardServiceMinimal>
                  </Service>

                  <Service>
                    <CardServiceMinimal className="cardServices">
                      <ColumnContainer style={{textAlign: 'left', padding: 30}}>
                        <TextDefault size="20px" color="#4b4b4b">Blockchain</TextDefault>
                        <TextDefault size="12px" color="#8a97aa" bold="700" style={{marginTop: 15, width: '70%'}}>This module provides a secure platform for smart contracts, sanctions, compliance, and other functions.</TextDefault>
                        <TextDefault size="16px" color="#4d01a6" style={{marginTop: 20}}>Coming Soon</TextDefault>
                      </ColumnContainer>
                      <RowContainer style={{position: 'absolute', width: 140}}>
                        <ImgDefault src={ServiceMenuBlockchain} style={{marginLeft: 100}}/>
                      </RowContainer>
                    </CardServiceMinimal>                    
                  </Service>
                </ServicesContainer>

                <FavoriteCompaniesContainer>
                  <ColumnContainer>
                    <TextDefault color={"#4b4b4b"} size={"19px"}>
                      Favorite Companies
                    </TextDefault>
                    <TextDefault
                      color={"#8a97aa"}
                      size={"12px"}
                      style={{ paddingBottom: 8 }}
                    >
                      Supplier and Buyer
                    </TextDefault>

                    <FavoriteCompanies>
                      {Array.isArray(companies) &&
                        companies.map((item, index) => (
                          <div key={index}>
                            <Partner data={item} />
                          </div>
                        ))}
                    </FavoriteCompanies>
                  </ColumnContainer>
                </FavoriteCompaniesContainer>

                
              </Container>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
