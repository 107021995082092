/* eslint-disable react/prop-types */

import { format } from "date-fns";
import { Container } from "./styles";

import Logotype from "../../../../../../../../icons/T-SaleMetals-03.png";
import { useEffect, useState } from "react";
import { auth } from "../../../../../../../../database/config";
import getUserInfo from "../../../../../../../../hooks/getUsers";
import { authScreen } from "../../../../../../../../contexts/auth";

export default function Content({ company, role }) {
  const [userInfo, setUserInfo] = useState(null);
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      }
    });
  });

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData();
    }
  }, [auth]);

  return (
    <Container>
      {userInfo && (
        <>
          <div className="bar" />
          <div>
            <h2>BUSINESS REPORT</h2>
            <span style={{ fontSize: 20 }}>
              {format(new Date(), "MM/dd/yyyy")} - {userInfo?.userData?.name}{" "}
              {userInfo?.userData?.lastName}
            </span>
            <br />
            <span>Country: {company?.country}</span>
            {company?.matchedHsCodes?.length > 0 && (
              <>
                <br />
                <span>Hs Code: {company?.matchedHsCodes?.map((hs) => hs)}</span>
              </>
            )}
            <br />
            <span>Role: {role}</span>
          </div>

          <footer>
            <img src={Logotype} alt="Logotype" />
            <small>
              Analytics data manage and offered by T-Sale Metals -
              www.tsalemetals.com - All rights reserved
            </small>
          </footer>
        </>
      )}
    </Container>
  );
}
