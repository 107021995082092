import React from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

const Partner = ({ data }) => {
  const country = [
    {
      src: require("../../../flag/united-states.png"),
      country: "United States",
    },
    { src: require("../../../flag/canada.png"), country: "Canada" },
    { src: require("../../../flag/brazil.png"), country: "Brazil" },
    { src: require("../../../flag/china.png"), country: "China" },
    { src: require("../../../flag/germany.png"), country: "germany" },
    { src: require("../../../flag/japan.png"), country: "japan" },
    {
      src: require("../../../flag/united-kingdom.png"),
      country: "united kingdom",
    },
    { src: require("../../../flag/france.png"), country: "france" },
    { src: require("../../../flag/netherlands.png"), country: "netherlands" },
    { src: require("../../../flag/belgium.png"), country: "belgium" },
    { src: require("../../../flag/india.png"), country: "india" },
    { src: require("../../../flag/vietnam.png"), country: "vietnam" },
    { src: require("../../../flag/turkey.png"), country: "turkey" },
  ];
  const [png, setPng] = useState(null);

  useEffect(() => {
    country.forEach((item) => {
      if (data.companyN == item.country) {
        setPng(item.src);
        return;
      }
    });
  }, []);

  return (
    <Paper style={{ padding: "16px", display: "flex" }}>
      <div style={{ marginRight: "16px" }}>
        <img
          style={{ width: "64px", height: "64px" }}
          src={png}
          alt="Company Logo"
        />
      </div>

      <div>
        <Typography variant="h6">
          {data.fantasy ? data.fantasy : data.corporateName}
        </Typography>
        <Chip label={data.activity} color="primary" size="small" />
        <br />
        <Typography variant="caption" noWrap>
          Learn more about this company...
        </Typography>
      </div>
    </Paper>
  );
};

export default Partner;

const styles = {
  container: {
    padding: 5,
    alignItems: "center",
  },
  desc: {
    fontSize: 12,
    color: "#8598ac",
    marginTop: 5,
  },
  img: {
    width: 60,
    height: 60,
  },
  formatList: {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 10,
    alignItems: "center",
    borderRadius: 12,
    width: "95%",
    paddingVertical: 15,
  },
  corporateName: {
    width: 150,
    color: "#8a97aa",
    fontWeight: "700",
  },
  hscode: {
    fontWeight: "700",
    color: "#fff",
    fontSize: 11,
  },
  viewHscode: {
    backgroundColor: "#a054fe",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    width: 68,
    marginTop: 3,
    paddingVertical: 2,
    // marginLeft: 10
  },
  cnpj: {
    width: 250,
    color: "#4d6484",
    marginTop: 10,
    maxWidth: 200,
  },
  hr: {
    height: 1,
    width: 390,
    backgroundColor: "#D2D2D2",
    left: 10,
    marginTop: 8,
  },
  imgBtn: {
    width: 25,
    height: 25,
  },
  indicador: {
    width: 5,
    height: 70,
    backgroundColor: "#8a97aa",
    right: 10,
  },
  btnNext: {
    marginLeft: 18,
  },
  btnView: {
    width: 35,
    height: 35,
    //backgroundColor: '#4abc96',
    backgroundColor: "#a054fe",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    fontWeight: "bold",
    color: "#FFF",
    fontSize: 15,
  },
  imgProduct: {
    width: 70,
    height: 70,
    tintColor: "#415fa2",
  },
  hsText: {
    marginHorizontal: 15,
  },
  hscodeAlign: {
    flexDirection: "row",
    alignItems: "center",
  },
  cardFundImg: {
    backgroundColor: "#e9edf8",
    width: 70,
    height: 70,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
};
