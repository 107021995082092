import React from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import country from "../../../components/Flag";
import { Container } from "./styles";
import Capitalize from "../../../utils/capitalize";
import {
  ColumnContainer,
  ImgDefault,
  RowContainer,
  TextDefault,
} from "../../../assets/styles";
import NextIcon from "../../../icons/nextv2.png";
import { useNavigate } from "react-router-dom";

const Partner = ({ data }) => {
  const [png, setPng] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    country.forEach((item) => {
      if (data.country.toLowerCase() === item.country.toLowerCase()) {
        return setPng(item.src);
      }
    });
  }, [data.country]);

  function linkSavedOperation() {
    navigate("/saved-operations");
  }

  return (
    <Container
      className="cardsFavoriteCompanies"
      style={{ width: "95%", marginBottom: 12, marginTop: 12 }}
      onClick={linkSavedOperation}
    >
      <div
        style={{
          marginRight: "16px",
          backgroundColor: "#e9edf8",
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 8,
        }}
      >
        <img
          style={{ width: "64px", height: "64px" }}
          src={png}
          alt="Company country"
        />
      </div>

      <RowContainer
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ColumnContainer style={{ width: "100%", justifyContent: "center" }}>
          <TextDefault
            className="lineLM1"
            color={"#4D6484"}
            size={"18px"}
            style={{ overflow: "hidden", width: "95%" }}
          >
            {data.companyName ? Capitalize(data.companyName) : "-"}
          </TextDefault>
          <TextDefault style={{ marginTop: 8 }} bold={"700"} size={'13px'} color={"#8a97aa"}>
            Learn more about this company...
          </TextDefault>
        </ColumnContainer>

        <ImgDefault src={NextIcon} width={"25px"} height={"25px"} />
      </RowContainer>
    </Container>
  );
};

export default Partner;
