export const data = [
    {
      controllingAuthority: "Australian Border Force (ABF)",
      notes: [
        "Goods intended to be exported from Australia must be declared on an Export Declaration if the goods: have a value of more than AUD2000, need an export permit, (regardless of their value), are goods where duty drawback is being claimed, are dutiable or excisable goods where the duty or excise duty is unpaid.",
      ],
      countryOfExport: "AU",
      rates: [],
      derivedReference: "​​​​Export requirements - Australia$$AU",
      countryOfImport: "AF",
      exceptions: "Personal Effects, Pets, Goods With A Value Of Less Than AUD 2000, Some Goods Temporarily Imported Under Section 162A Of The Customs Act 1901, Australia Post Or Diplomatic Bags Of Mail, Australian Aircraft And Ships' Spares, Military Goods Of Any Value That Are The Property Of Australian Government, For Use Overseas By Australian Defence Forces, Australian Domestic Cargo, Containers For The International Carriage Of Cargo And Ships' Stores.",
      reference: "​​​​Export requirements - Australia",
      countryOfOrigin: "AU",
      scopeOfControl: "Goods falling in this Heading",
      applicableCodes: [],
      nonApplicableCodes: [],
      footnotes: [],
      controlType: "Export Declaration",
      hsCode: "7204",
    },
  ];
  
  export default data;
