import React from "react";
import PDF from "../../../icons/pdf.png";
import { BookmarkAdd } from "@mui/icons-material";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

export default function PdfExport({ action, saveOp, fndp }) {
  return (
    <div style={{ position: "absolute", right: 50, top: -30 }}>
      <ul className="wrapper">
        <li className="icon twitter" onClick={() => action("AQUILOG")}>
          <span className="tooltip">PDF</span>
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          <img src={PDF} style={{ width: 25, height: 25 }} alt="PDF Icon" />
        </li>    
        <li className="icon instagram" onClick={saveOp}>
          <span className="tooltip">Save operation</span>
          <span>
            <i className="fab fa-instagram"></i>
          </span>
          <BookmarkAdd sx={{ color: "#002C67" }} />
        </li>  
        <li className="icon instagram" onClick={fndp}>
          <span className="tooltip">Find New...</span>
          <span>
            <i className="fab fa-instagram"></i>
          </span>
          <TravelExploreIcon sx={{ color: "#002C67" }} />
        </li>  
      </ul>
    </div>
  );
}
