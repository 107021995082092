import React, { useEffect, useState } from "react";
import { ContainerHome, TextDefault } from "../../assets/styles";
import Drawer from "../../components/Drawer";
import { Box, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import { authScreen } from "../../contexts/auth";
import getUserInfo, { getHistoryCredits } from "../../hooks/getUsers";
import { stripeService } from "../../service/apiStripe";
import { viewCredit } from "../../hooks/credits";
import BackVerde from "../../icons/mapaAzul.jpg";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Cards from "./components/cards";
import { StatusToTag } from "../../utils/statusToTag";

export default function Billing() {
  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setCustomerId(userData?.userData?.customerId);
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [subscriptionDois, setSubscriptionDois] = useState(null);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (userInfo) {
        try {
          setIsLoading(true);

          const response = await stripeService.getInfoSubscription(customerId);
          if (Array.isArray(response) && response.length === 2) {
            setSubscriptionInfo(response[0]);
            setSubscriptionDois(response[1]);
          } else if (Array.isArray(response) && response.length === 1) {
            setSubscriptionInfo(response[0]);
          } else {
            console.error(
              "Resposta inesperada do serviço de informações de assinatura"
            );
          }

          setIsLoading(false);
        } catch (error) {
          console.error("Erro ao buscar informações da assinatura:", error);
        }
      }
    };

    fetchSubscriptionInfo();
  }, [userInfo, customerId]);

  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      if (userInfo) {
        try {
          const userCredits = await viewCredit(userInfo?.uid);
          setCredits(userCredits);
        } catch (error) {
          console.error("Erro ao buscar os créditos do usuário:", error);
        }
      }
    };

    fetchCredits();
  }, [userInfo]);

  async function createPortal() {
    if (customerId) {
      try {
        const response = await stripeService.createportal(customerId);
        const newWindow = window.open(response?.data?.url, "_blank");
        newWindow.focus();
      } catch (error) {
        console.error("Erro ao gerar o token:", error);
      }
    }
  }

  function expenses() {
    const newWindow = window.open("/expenses", "_blank");
  }

  const [historyC, setHistoryC] = useState([]);

  const address = {
    city: userInfo?.userData?.address?.city,
    country: userInfo?.userData?.address?.country,
    line1: userInfo?.userData?.address?.line1,
    postalCode: userInfo?.userData?.address?.postalCode,
    state: userInfo?.userData?.address?.state,
  };

  const info = {
    name: userInfo?.userData?.name,
    customerId: userInfo?.userData?.customerId,
    email: userInfo?.email,
    userId: userInfo?.uid,
  };

  const priceId = "price_1NhZxbJcSI08Gr0K3XUEMpYS";

  useEffect(() => {
    if (userInfo) {
      getHistoryCredits(userInfo?.uid)
        .then((dados) => {
          if (dados) {
            const historyArray = Object.values(dados);
            setHistoryC(historyArray);
          } else {
            setHistoryC(["No history"]);
          }
        })
        .catch((error) => {
          console.error("Erro ao verificar os dados do histórico:", error);
        });
    }
  }, [userInfo]);

  const totalDecreaseCredits = historyC
    .filter((item) => item.type === "decrease")
    .reduce((total, item) => {
      if (!isNaN(item.credits)) {
        return total + item.credits;
      }

      return total + 0;
    }, 0);

  const totalEarnedCredits = historyC
    .filter((item) => item.type === "increase")
    .reduce((total, item) => total + item.credits, 0);

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            width={"100%"}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <Grid
              sx={[
                {
                  marginLeft: "75px",
                  marginTop: "30px",
                },
                toggleDrawer
                  ? {
                      width: "70%",
                    }
                  : {
                      width: "85%",
                    },
              ]}
            >
              <TextDefault color={"#4b4b4b"} size={"32px"}>
                Hello, {userInfo?.userData?.name} {userInfo?.userData?.lastName}
              </TextDefault>

              <Box
                width="100%"
                height="150px"
                borderRadius="20px"
                style={{
                  backgroundColor: "#265DD8",
                  backgroundSize: "cover",
                  marginBottom: "150px",

                  paddingLeft: "120px",
                }}
                position="relative"
              >
                <div
                  style={{
                    width: "80%",
                    position: "absolute",
                    top: "70px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "24px",
                  }}
                >
                  <Paper
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "150px",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      padding: "10px",
                    }}
                  >
                    <br />
                    <ViewCarouselIcon
                      style={{ fontSize: 48, color: "#234392" }}
                    />
                    <br />
                    <br />
                    <TextDefault bold={100}> Subscription: </TextDefault>
                    <TextDefault>
                      {" "}
                      {subscriptionInfo?.name}{" "}
                      {subscriptionDois ? `& ${subscriptionDois.name}` : ""}{" "}
                    </TextDefault>
                    <br />
                    <TextDefault bold={100}> Status: </TextDefault>
                    <TextDefault>
                      {" "}
                      <StatusToTag
                        status={
                          subscriptionInfo?.active === true ? "true" : "false"
                        }
                      />{" "}
                    </TextDefault>
                  </Paper>
                  <Paper
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "150px",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      // padding: "10px",
                    }}
                  >
                    <br />
                    <CurrencyExchangeIcon
                      style={{ fontSize: 48, color: "#234392", marginLeft: 10 }}
                    />
                    <br />
                    <br />
                    <TextDefault bold={100} style={{ marginLeft: 10 }}>
                      {" "}
                      Earned credits:{" "}
                    </TextDefault>
                    <TextDefault> {totalEarnedCredits} units </TextDefault>
                    <br />
                    <TextDefault bold={100} style={{ marginLeft: 10 }}>
                      {" "}
                      Credits used:{" "}
                    </TextDefault>
                    <TextDefault>
                      {" "}
                      {totalDecreaseCredits > 5000000
                        ? "5.000.000+"
                        : parseFloat(totalDecreaseCredits)}{" "}
                      units{" "}
                    </TextDefault>
                    <br />
                    <TextDefault bold={100} style={{ marginLeft: 10 }}>
                      {" "}
                      Balance:{" "}
                    </TextDefault>
                    <TextDefault> {credits} units </TextDefault>
                  </Paper>
                  <Paper
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "150px",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/plans")}
                  >
                    <br />
                    <MiscellaneousServicesIcon
                      style={{ fontSize: 48, color: "#234392" }}
                    />
                    <br />
                    <br />
                    <TextDefault bold={100}> Manage Subscriptions </TextDefault>
                  </Paper>
                  <Paper
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "150px",
                      backgroundColor: "white",
                      borderRadius: "20px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={expenses}
                  >
                    <br />
                    <ReceiptIcon style={{ fontSize: 48, color: "#234392" }} />

                    <br />
                    <br />
                    <TextDefault bold={100}>
                      Payments & Historic Credits{" "}
                    </TextDefault>
                  </Paper>
                </div>
              </Box>

              <Cards
                info={info}
                priceId={priceId}
                address={address}
                userId={userInfo?.uid}
              />
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
