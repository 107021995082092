import React from 'react';
import { Modal } from 'antd';
import CheckoutCredits from '../../checkout/checkoutCredits';

const PurchaseModalCredits = ({ visible, onCancel, credits, userId, clientSecret }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <CheckoutCredits credits={credits} userId={userId} clientSecret={clientSecret} />
    </Modal>
  );
};

export default PurchaseModalCredits;
