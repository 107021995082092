// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    text: "You used 8 credits with research in Oil Prices",
    credits: 8,
    type: "decrease",
    date: "Thu Sep 30 2023 15:25:25 GMT-0300",
  },
  {
    text: "You used 3 credits with research in Market Trends",
    credits: 3,
    type: "increase",
    date: "Sat Oct 15 2023 10:45:00 GMT-0300",
  },
  {
    text: "You used 10 credits with research in Renewable Energy",
    credits: 10,
    type: "decrease",
    date: "Sun Oct 20 2023 14:30:45 GMT-0300",
  },
  {
    text: "You used 6 credits with research in Global Investments",
    credits: 6,
    type: "increase",
    date: "Tue Oct 25 2023 18:55:30 GMT-0300",
  },
  {
    text: "You used 12 credits with research in Emerging Technologies",
    credits: 12,
    type: "decrease",
    date: "Thu Oct 27 2023 09:20:15 GMT-0300",
  },
  {
    text: "You used 3 credits with research in Market Trends",
    credits: 3,
    type: "increase",
    date: "Sat Oct 15 2023 10:45:00 GMT-0300",
  },
  {
    text: "You used 10 credits with research in Renewable Energy",
    credits: 10,
    type: "decrease",
    date: "Sun Oct 20 2023 14:30:45 GMT-0300",
  },
  {
    text: "You used 6 credits with research in Global Investments",
    credits: 6,
    type: "increase",
    date: "Tue Oct 25 2023 18:55:30 GMT-0300",
  },
  {
    text: "You used 12 credits with research in Emerging Technologies",
    credits: 12,
    type: "decrease",
    date: "Thu Oct 27 2023 09:20:15 GMT-0300",
  },
  {
    text: "You used 3 credits with research in Market Trends",
    credits: 3,
    type: "increase",
    date: "Sat Oct 15 2023 10:45:00 GMT-0300",
  },
  {
    text: "You used 10 credits with research in Renewable Energy",
    credits: 10,
    type: "decrease",
    date: "Sun Oct 20 2023 14:30:45 GMT-0300",
  },
  {
    text: "You used 6 credits with research in Global Investments",
    credits: 6,
    type: "increase",
    date: "Tue Oct 25 2023 18:55:30 GMT-0300",
  },
  {
    text: "You used 12 credits with research in Emerging Technologies",
    credits: 12,
    type: "decrease",
    date: "Thu Oct 27 2023 09:20:15 GMT-0300",
  },
  {
    text: "You used 3 credits with research in Market Trends",
    credits: 3,
    type: "increase",
    date: "Sat Oct 15 2023 10:45:00 GMT-0300",
  },
  {
    text: "You used 10 credits with research in Renewable Energy",
    credits: 10,
    type: "decrease",
    date: "Sun Oct 20 2023 14:30:45 GMT-0300",
  },
  {
    text: "You used 6 credits with research in Global Investments",
    credits: 6,
    type: "increase",
    date: "Tue Oct 25 2023 18:55:30 GMT-0300",
  },
  {
    text: "You used 12 credits with research in Emerging Technologies",
    credits: 12,
    type: "decrease",
    date: "Thu Oct 27 2023 09:20:15 GMT-0300",
  },
  {
    text: "You used 3 credits with research in Market Trends",
    credits: 3,
    type: "increase",
    date: "Sat Oct 15 2023 10:45:00 GMT-0300",
  },
  {
    text: "You used 10 credits with research in Renewable Energy",
    credits: 10,
    type: "decrease",
    date: "Sun Oct 20 2023 14:30:45 GMT-0300",
  },
  {
    text: "You used 6 credits with research in Global Investments",
    credits: 6,
    type: "increase",
    date: "Tue Oct 25 2023 18:55:30 GMT-0300",
  },
  {
    text: "You used 12 credits with research in Emerging Technologies",
    credits: 12,
    type: "decrease",
    date: "Thu Oct 27 2023 09:20:15 GMT-0300",
  },
];
