import React from "react";
import { CardContent, Typography } from "@mui/material";
import { TagInfo } from "../../pages/stayInformed/utils/tagToInfo";
import { Link } from "react-router-dom";
import { Card, Container, Header } from "./styles";

function NewsCard({ item, index }) {
  function cleanContent(text) {
    const regex = /^[^,]+, \d+ \w+ \(Argus\) —/;
    return text.replace(regex, "").trim();
  }

  function savePublishDate(text) {
    const regex = /^[^,]+, \d+ \w+ \(Argus\)/;
    // Encontre a primeira parte
    const publishedDate = text.match(regex)[0];

    return publishedDate;
  }

  return (
    <Card>
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        key={index}
        to={`/stayinformed/${item.id}`}
      >
        <Header
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            marginTop: "15px",
            marginRight: "5px",
            marginLeft: "5px",
          }}
        >
          <article style={{ paddingRight: 12 }}>
            <h2>{item.text}</h2>

            <TagInfo type={item.type} />
          </article>

          <p>{savePublishDate(item.content)}</p>
        </Header>

        <CardContent>
          <Typography
            variant="body2"
            style={{
              textAlign: "justify",
              maxHeight: "4em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {cleanContent(item.content)}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
}

function NewsList({ data }) {
  const items = Object.values(data).slice(0, 6);
console.log(items)
  return (
    <Container>
      {items.map((item, index) => (
        <NewsCard key={item.id} item={item} index={index} />
      ))}
    </Container>
  );
}

export default NewsList;
