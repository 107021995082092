/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FileDownloadOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { Table, Collapse, message, Space } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Pdf } from "../Pdf";
import * as XLSX from "xlsx";
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ThemeContext } from "styled-components";

import {
  ColumnContainer,
  RowContainer,
  TextDefault,
} from "../../../../assets/styles";
import {
  TablesContainer,
  SanctionContainer,
  SanctionImage,
  TableNavigator,
  Option,
  NavigationContainer,
  DownloadButton,
  ShipmentsDownload,
} from "./styles";
import "./styles.css";

import Capitalize from "../../../../utils/capitalize";
import ShipmentsCompany from "../../../../service/Shipments";

import ButtonBlue from "../../../../components/myButton";
import Spinner from "../../../../components/Spinner";
import CardSanction from "../sanctionCard";
import Header from "./components/Header";
import CountryTable from "./components/CountryTable";
import TradePartnersTable from "./components/tradePartnersTable";
import ProductKeywords from "./components/ProductKeywords";
import PortsTable from "./components/PortsTable";
import HsCodesTable from "./components/HsCodesTable";
import CardFavoriteSanction from "../sanctionFavorite";

import { PopOver } from "../../../../components/PopOver";

import { publicIpv4 } from "public-ip";
import formatToCurrency from "../../../../utils/formatToCurrency";
import formatNumber from "../../../../utils/numberFormat";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Shipments({ shipments, companyName, country }) {
  const textStyle = { color: "#4D6484", height: "100%" };
  const truncatedCompanyName = companyName?.substring(0, 12);
  const capitalizedTruncatedCompanyName =
    truncatedCompanyName?.charAt(0)?.toUpperCase() +
    truncatedCompanyName?.slice(1);

  const sheetName = `${capitalizedTruncatedCompanyName} - Shipments Info`;

  const excel = () => {
    const ws = XLSX.utils.json_to_sheet(shipments);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, sheetName + ".xlsx");
  };

  console.log({ shipments });

  return (
    <>
      {shipments?.length > 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div style={{ gridColumn: "1/-1" }}>
            {shipments?.length > 0 ? (
              <>
                <RowContainer
                  style={{
                    marginTop: 12,
                    marginBottom: 8,
                    justifyContent: "space-between",
                  }}
                >
                  <ColumnContainer>
                    <TextDefault size={"24px"} color={"#4b4b4b"}>
                      Shipments
                    </TextDefault>
                    <TextDefault color={"#8a97aa"} bold={"400"}>
                      Shipments Info
                    </TextDefault>
                  </ColumnContainer>

                  <ShipmentsDownload onClick={excel}>
                    <span>Download</span>
                    <FileDownloadOutlined
                      sx={{
                        fontSize: "24px",
                        color: "#fafafa",
                      }}
                    />
                  </ShipmentsDownload>
                </RowContainer>

                <Box sx={{ height: "85%", width: "98%" }}>
                  <Table
                    dataSource={shipments}
                    columns={[
                      {
                        dataIndex: "consigneeName",
                        title: "Company Name",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record?.consigneeName === ""
                              ? "Not Informed"
                              : Capitalize(record?.consigneeName)
                          }`,
                      },
                      {
                        dataIndex: "shipmentDate",
                        title: "Shipment Date",
                        flex: 1,
                        render: (_, record) =>
                          format(new Date(record.shipmentDate), "MM/dd/yyyy"),
                      },
                      {
                        dataIndex: "country",
                        title: "Country",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record?.consigneeCountry === ""
                              ? "Not Informed"
                              : Capitalize(record?.consigneeCountry)
                          }`,
                      },
                      {
                        dataIndex: "hscode",
                        title: "Hs Code",
                        flex: 1,
                        render: (_, record) => record?.hsCode[0],
                      },
                      {
                        dataIndex: "productDescription",
                        title: "Key Product",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record?.productDescription === ""
                              ? "Not Informed"
                              : record?.productDescription.replace(
                                  /undefined/gi,
                                  ""
                                )
                          }`,
                      },
                      {
                        dataIndex: "shipmentValue",
                        title: "Shipment Value (USD)",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record.shipmentValue < 0
                              ? "Not Informed"
                              : formatToCurrency(record?.shipmentValue)
                          }`,
                      },
                      {
                        dataIndex: "shipmentWeight",
                        title: "Shipment Weight (Ton.)",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record.shipmentWeight < 0
                              ? "Not Informed"
                              : formatNumber(record?.shipmentWeight)
                          }`,
                      },
                      {
                        dataIndex: "shipperCountry",
                        title: "Operation Type",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record?.shipperCountry === country
                              ? "Exportation"
                              : "Importation"
                          }`,
                      },
                      {
                        dataIndex: "modeOfTransportation",
                        title: "Via",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record?.modeOfTransportation === ""
                              ? "Not Informed"
                              : Capitalize(record?.modeOfTransportation)
                          }`,
                      },
                      {
                        dataIndex: "portOfLading",
                        title: "Port Of Lading",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record.portOfLading === ""
                              ? "Not Informed"
                              : Capitalize(record?.portOfLading)
                          }`,
                      },
                      {
                        dataIndex: "portOfUnlading",
                        title: "Port Of Unlading",
                        flex: 1,
                        render: (_, record) =>
                          `${
                            record.portOfUnlading === ""
                              ? "Not Informed"
                              : Capitalize(record?.portOfUnlading)
                          }`,
                      },
                    ]}
                    style={textStyle}
                    pagination={false}
                  />
                </Box>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div>
          <h1 style={{ color: "#bbb" }}>- No Data Avaliable -</h1>
        </div>
      )}
    </>
  );
}

function Tables({
  companyProfile,
  renderFlag,
  role,
  supplierOrBuyer,
  handleScroll,
  selectedId,
}) {
  const theme = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);

  const panelStyle = {
    marginBottom: 24,
    background: "#E9EDF8",
    borderRadius: 8,
    border: "none",
  };

  const truncatedCompanyName = companyProfile?.companyName?.substring(0, 12);
  const capitalizedTruncatedCompanyName =
    truncatedCompanyName?.charAt(0)?.toUpperCase() +
    truncatedCompanyName?.slice(1);

  const excel = (data, title) => {
    setIsLoading(true);
    const sheetName = `${capitalizedTruncatedCompanyName} - ${title}`;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, sheetName + ".xlsx");
    setIsLoading(false);
  };

  const Header = (title, description, id) => {
    const handleDownload = () => {
      if (role === "export") {
        if (title === "Countries Exporting To") {
          const titulo = "CET";
          excel(companyProfile.countriesExportingTo, titulo);
        } else if (title.includes("Export Trading Partners")) {
          const titulo = "ETP";
          excel(companyProfile.exportTradingPartners, titulo);
        } else if (title === "Exported Product Keywords") {
          const titulo = "EPK";
          excel(companyProfile.exportedProductKeywords, titulo);
        } else if (title === "Ports Of Lading") {
          const titulo = "POL";
          excel(companyProfile.portsOfLading, titulo);
        } else if (title === "Exported 6 Digits HsCode") {
          const titulo = "EHC";
          excel(companyProfile.exported6DigitHsCodes, titulo);
        }
      } else {
        if (title === "Countries Importing From") {
          const titulo = "CIF";
          excel(companyProfile.countriesImportingFrom, titulo);
        } else if (title.includes("Import Trading Partners")) {
          const titulo = "ITP";
          excel(companyProfile.importTradingPartners, titulo);
        } else if (title === "Imported Product Keywords") {
          const titulo = "IPK";
          excel(companyProfile.imported6DigitHsCodes, titulo);
        } else if (title === "Ports Of Unlading") {
          const titulo = "POU";
          excel(companyProfile.portsOfUnlading, titulo);
        } else if (title === "Imported 6 Digits HsCode") {
          const titulo = "IHC";
          excel(companyProfile.imported6DigitHsCodes, titulo);
        }
      }
    };

    return (
      <RowContainer
        style={{
          marginTop: 12,
          marginBottom: 8,
          alignItems: "center",
          justifyContent: "space-between",
        }}
        id={id}
      >
        <ColumnContainer>
          <TextDefault size={"24px"} color={theme.colors.dark[950]}>
            {title}
          </TextDefault>
          <TextDefault color={"#5B5B5B"} bold={"400"}>
            {description}
          </TextDefault>
        </ColumnContainer>

        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <DownloadButton onClick={handleDownload}>
            <FileDownloadOutlined
              sx={{
                fontSize: "28px",
                color: theme.colors.main[500],
              }}
            />
          </DownloadButton>
        )}
      </RowContainer>
    );
  };

  const exportData = [
    companyProfile.exportTradingPartners?.length > 0 && {
      key: "1",
      id: "exportTrade",
      label: Header(
        `Export Trading Partners (Top ${companyProfile.exportTradingPartners?.length})`,
        "Top Trading Partners",
        "exportTrade"
      ),
      children: (
        <TradePartnersTable
          data={companyProfile.exportTradingPartners}
          renderFlag={renderFlag}
          role={supplierOrBuyer}
        />
      ),
      style: panelStyle,
    },
    companyProfile.exportedProductKeywords?.length > 0 && {
      key: "2",
      id: "exportProducts",
      label: Header(
        "Exported Product Keywords",
        "Top Products",
        "exportProducts"
      ),
      children: (
        <ProductKeywords data={companyProfile.exportedProductKeywords} />
      ),
      style: panelStyle,
    },
    companyProfile.portsOfLading?.length > 0 && {
      key: "3",
      id: "ladingPorts",
      label: Header("Ports Of Lading", "Top Ports", "ladingPorts"),
      children: <PortsTable data={companyProfile.portsOfLading} />,
      style: panelStyle,
    },
    companyProfile.countriesExportingTo?.length > 0 && {
      key: "4",
      id: "countryExport",
      label: Header(
        "Countries Exporting To",
        "Top Destinations",
        "countryExport"
      ),
      children: (
        <CountryTable
          data={companyProfile.countriesExportingTo}
          renderFlag={renderFlag}
        />
      ),
      style: panelStyle,
    },
    companyProfile.exported6DigitHsCodes?.length > 0 && {
      key: "5",
      id: "exportedHs",
      label: Header("Exported 6 Digits Hs Code", "Top Hs Code", "exportedHs"),
      children: <HsCodesTable data={companyProfile.exported6DigitHsCodes} />,
      style: panelStyle,
    },
  ].filter((data) => typeof data !== "boolean");

  const importData = [
    companyProfile.importTradingPartners?.length > 0 && {
      key: "1",
      id: "importTrade",
      label: Header(
        `Import Trading Partners (Top ${companyProfile.exportTradingPartners?.length})`,
        "Top Trading Partners",
        "importTrade"
      ),
      children: (
        <TradePartnersTable
          data={companyProfile.importTradingPartners}
          renderFlag={renderFlag}
        />
      ),
      style: panelStyle,
    },
    companyProfile.importedProductKeywords?.length > 0 && {
      key: "2",
      id: "ImportProducts",
      label: Header(
        "Imported Product Keywords",
        "Top Products",
        "ImportProducts"
      ),
      children: (
        <ProductKeywords data={companyProfile.importedProductKeywords} />
      ),
      style: panelStyle,
    },
    companyProfile.portsOfUnlading?.length > 0 && {
      key: "3",
      id: "unladingPorts",
      label: Header("Ports Of Unlading", "Top Ports", "unladingPorts"),
      children: <PortsTable data={companyProfile.portsOfUnlading} />,
      style: panelStyle,
    },
    companyProfile.countriesImportingFrom?.length > 0 && {
      key: "4",
      id: "countryImport",
      label: Header(
        "Countries Importing From",
        "Top Destinations",
        "countryImport"
      ),
      children: (
        <CountryTable
          data={companyProfile.countriesImportingFrom}
          renderFlag={renderFlag}
        />
      ),
      style: panelStyle,
    },
    companyProfile.imported6DigitHsCodes?.length > 0 && {
      key: "5",
      id: "importedHs",
      label: Header("Imported 6 Digits Hs Code", "Top HsCodes", "importedHs"),
      children: <HsCodesTable data={companyProfile.imported6DigitHsCodes} />,
      style: panelStyle,
    },
  ].filter((data) => typeof data !== "boolean");

  const selectedTable = useMemo(
    () =>
      role === "import"
        ? importData.find((table) => selectedId === table.id)?.key
        : exportData.find((table) => selectedId === table.id)?.key,
    [selectedId]
  );

  return (
    <Collapse
      items={role === "import" ? importData : exportData}
      bordered={false}
      activeKey={selectedTable}
      style={{
        background: "transparent",
      }}
      onChange={(e) => {
        const lastSelected = e[e.length - 1];

        const selectedtable =
          role === "import"
            ? importData.find((table) => lastSelected === table.key)
            : exportData.find((table) => lastSelected === table.key);

        selectedtable ? handleScroll(selectedtable?.id) : handleScroll("");
      }}
      expandIcon={({ isActive }) => (
        <RightOutlined
          rotate={isActive ? 90 : 0}
          style={{ fontSize: "20px", color: `` }}
        />
      )}
    />
  );
}

export default function CompanyProfile({
  toggleDrawer,
  animatedStep,
  moveStepAnimation,
  supplierOrBuyer,
  companySelect,
  renderFlag,
  saveCompany,
  selectedInfo,
  handleSelectInfo,
  hsCode,
  companies,
  fromDate,
  toDate,
  requestType,
  userId,
  userName,
}) {
  const [hasImportData, setHasImportData] = useState(true);
  const [hasExportData, setHasExportData] = useState(true);
  const [sanctionStep, setSanctionStep] = useState(0);
  const [selectedTable, setSelectedTable] = useState("");
  const [dataEmails, setDataEmails] = useState();
  const [companyLinkedin, setCompanyLinkedin] = useState();
  const [isLeadsLoading, setIsLeadsLoading] = useState(true);
  const [shipments, setShipments] = useState([]);

  const targetRef = useRef();

  const handlePrintProfile = useReactToPrint({
    content: () => targetRef.current,
  });

  useEffect(() => {
    const TitlePage = Capitalize(companySelect[0].companyName);

    document.title = TitlePage;
  }, [companySelect]);

  const ExpTablesAndHashs = [
    companySelect[0].exportTradingPartners?.length > 0 && {
      id: "exportTrade",
      label: `Export Trading Partners (Top ${companySelect[0].exportTradingPartners?.length})`,
    },
    companySelect[0].exportedProductKeywords?.length > 0 && {
      id: "exportProducts",
      label: "Exported Product Keywords",
    },
    companySelect[0].portsOfLading?.length > 0 && {
      id: "ladingPorts",
      label: "Ports Of Lading",
    },
    companySelect[0].countriesExportingTo?.length > 0 && {
      id: "countryExport",
      label: "Countries Exporting To",
    },

    companySelect[0].exported6DigitHsCodes?.length > 0 && {
      id: "exportedHs",
      label: "Exported 6 Digits Hs Code",
    },
  ].filter((data) => typeof data !== "boolean");

  const ImpTablesAndHashs = [
    companySelect[0].importTradingPartners?.length > 0 && {
      id: "importTrade",
      label: `Import Trading Partners (Top ${companySelect[0].exportTradingPartners?.length})`,
    },

    companySelect[0].importedProductKeywords?.length > 0 && {
      id: "importProducts",
      label: "Imported Product Keywords",
    },
    companySelect[0].portsOfUnlading?.length > 0 && {
      id: "unladingPorts",
      label: "Ports Of Unlading",
    },
    companySelect[0].countriesImportingFrom?.length > 0 && {
      id: "countryImport",
      label: "Countries Importing From",
    },

    companySelect[0].imported6DigitHsCodes?.length > 0 && {
      id: "importedHs",
      label: "Imported 6 Digits Hs Code",
    },
  ].filter((data) => typeof data !== "boolean");

  useEffect(() => {
    if (companySelect) {
      saveCompany();

      setHasExportData(
        companySelect[0]?.portsOfLading?.length > 0 ||
          companySelect[0]?.exportedProductKeywords?.length > 0 ||
          companySelect[0]?.exportTradingPartners?.length > 0 ||
          companySelect[0]?.countriesExportingTo?.length > 0
      );

      setHasImportData(
        companySelect[0]?.portsOfUnlading?.length > 0 ||
          companySelect[0]?.importedProductKeywords?.length > 0 ||
          companySelect[0]?.importTradingPartners?.length > 0 ||
          companySelect[0]?.countriesImportingFrom?.length > 0
      );
      // fetchData();
      filterShipments();
    }
  }, [companySelect]);

  function filterShipments() {
    if (companySelect[0].shipments?.length === 0 && requestType === "api") {
      const id = companySelect[0]?.id
        ? companySelect[0].companyId
        : companySelect[0].id;

      ShipmentsCompany(
        id,
        companySelect[0].companyName,
        companySelect[0].country
      ).then((res) => setShipments(res));
      return;
    }

    return setShipments(companySelect[0].shipments);
  }

  // const requestSanctions = async (number, attempt = 0) => {
  //   if (attempt >= 3) {
  //     console.log("Limite de tentativas excedido.");
  //     return;
  //   }

  //   const response = await ApiSanction(number, entityName, dataSource, uid, countryState, address);
  //   console.log("res", response);

  //   if (response.length === 0 && number > 50) {
  //     return requestSanctions(number - 25, attempt + 1); // Faça uma nova solicitação com number reduzido em 25 e incremente a tentativa
  //   } else {
  //     setData(response);
  //   }
  // };

  // // Chame a função inicialmente com number = 100
  // requestSanctions(100).catch(err => {
  //   console.error("Erro na requisição: " + err);
  //   if (err === "empty") {
  //     console.log("Resposta Vazia");
  //   }
  // });

  const [companyName, setCompanyName] = useState("");
  const [listName, setListName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [activeItem, setActiveItem] = useState("CompanyProfile");
  const [selectedListName, setSelectedListName] = useState("");
  const [linkedin, setLinkedin] = useState();
  const [dataEmail, setDataEmail] = useState();
  const [showLinkedinEnrichment, setShowLinkedinEnrichment] = useState(false);
  const [countrySel, setCountrySel] = useState("");
  const [lists, setLists] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenList, setIsModalOpenList] = useState(false);
  const [userIP, setUserIP] = useState("");

  useEffect(() => {
    (async () => {
      setUserIP(await publicIpv4());
    })();
  }, []);
  function handleCompanyName(event) {
    setCompanyName(event.target.value);
  }

  const handleListName = (e) => {
    const { value } = e.target;
    setListName(value);
    if (value) {
      const filtered = lists.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(lists);
    }
  };

  function handleEmployeeName(event) {
    setEmployeeName(event.target.value);
  }

  const handleClick = (item) => {
    setActiveItem(item);
  };

  function buscar() {
    if (activeItem === "SearchContact") {
      if (!companyName || !employeeName) {
        let errorMessage = "Please fill in the following fields:";
        if (!companyName) errorMessage += " Employee Name";
        if (!employeeName) errorMessage += " Company Website";

        message.error(errorMessage);
        return;
      } else {
        setShowLinkedinEnrichment(true);
      }
    }
  }

  async function fetchData() {
    setIsLeadsLoading(true);
    try {
      const response2 = await axios.post(
        "https://api.cufinder.io/v1/company_enrichment",
        {
          apiKey: "5j5rnrAbllkY2zku98ophd49uNpxqUjMfCmSCx77",
          companyName: companySelect[0].companyName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setDataEmails(response2.data);

      const response3 = await axios.post(
        "https://api.cufinder.io/v1/lcuf_employee",
        {
          apiKey: "5j5rnrAbllkY2zku98ophd49uNpxqUjMfCmSCx77",
          companyName: companySelect[0].companyName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCompanyLinkedin(response3.data);
    } catch (error) {
      console.error("Erro:", error);
    } finally {
      setIsLeadsLoading(false);
    }
  }

  const getSanctions = () => {
    setSanctionStep(1);
    // requestSanctions();
  };

  const handleClickScroll = (id) => {
    setSelectedTable(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRename = (name) => {
    setSelectedListName(name);
    setIsModalOpen(true);
  };

  const OpenModal = () => {
    setIsModalOpen(true);
  };

  const CloseModal = () => {
    setIsModalOpen(false);
  };

  const OpenModalList = () => {
    setIsModalOpenList(true);
  };

  const CloseModalList = () => {
    setIsModalOpenList(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${year}`;
    return formattedDate;
  };

  const result = {
    confidence_level: dataEmail?.confidence_level,
    linkedin_url: dataEmail?.query,
    name: employeeName,
    title: dataEmail?.job_title,
    emails: dataEmail?.emails,
    phones: dataEmail?.phones,
  };

  const dataArray = [result];

  return (
    <Grid item xs={toggleDrawer ? 10 : 11} container className={animatedStep}>
      <Grid item xs={12} style={{ marginBottom: 50 }}>
        <div
          style={{
            top: 0,
            paddingTop: 24,
            zIndex: 50,
            background: "#ffffff",
          }}
        >
          <Header
            onPrint={handlePrintProfile}
            onSave={saveCompany}
            companySelect={companySelect}
            supplierOrBuyer={supplierOrBuyer}
            moveStepAnimation={moveStepAnimation}
            renderFlag={renderFlag}
            excelData={companySelect}
          />

          <Tabs
            value={selectedInfo}
            onChange={handleSelectInfo}
            aria-label="Filter by"
            style={{
              marginTop: -40,
              width: "98%",
              borderBottom: "1px solid #d9d9d9",
            }}
          >
            <Tab label="Export Data" {...allyProps(0)} />
            <Tab label="Import Data" {...allyProps(1)} />
            <Tab label="Shipments" {...allyProps(2)} />
            {/* <Tab label="Leads" {...allyProps(3)} /> */}
            {/* <Tab label="Due Diligence Performance" {...allyProps(5)} /> */}
            {/* <Tab label="PDF" {...allyProps(4)} /> */}
          </Tabs>
        </div>

        <CustomTabPanel value={selectedInfo} index={0}>
          {hasExportData ? (
            <TablesContainer>
              <Tables
                companyProfile={companySelect[0]}
                renderFlag={renderFlag}
                role={"export"}
                handleScroll={setSelectedTable}
                selectedId={selectedTable}
              />

              <NavigationContainer>
                <TableNavigator>
                  {ExpTablesAndHashs.map((tab) => (
                    <Option
                      onClick={() => handleClickScroll(tab.id)}
                      key={tab.id}
                      isActive={selectedTable === tab.id}
                    >
                      <div className="navContent">
                        <p>{tab.label}</p>
                      </div>
                    </Option>
                  ))}
                </TableNavigator>
              </NavigationContainer>
            </TablesContainer>
          ) : (
            <div>
              <h1 style={{ color: "#bbb" }}>- No Data Avaliable -</h1>
            </div>
          )}
        </CustomTabPanel>

        <CustomTabPanel value={selectedInfo} index={1}>
          {hasImportData ? (
            <TablesContainer>
              <Tables
                companyProfile={companySelect[0]}
                renderFlag={renderFlag}
                role={"import"}
                handleScroll={setSelectedTable}
              />

              <NavigationContainer>
                <TableNavigator>
                  {ImpTablesAndHashs.map((tab) => (
                    <Option
                      onClick={() => handleClickScroll(tab.id)}
                      key={tab.id}
                      isActive={selectedTable === tab.id}
                    >
                      <div className="navContent">
                        <p>{tab.label}</p>
                      </div>
                    </Option>
                  ))}
                </TableNavigator>
              </NavigationContainer>
            </TablesContainer>
          ) : (
            <div>
              <h1 style={{ color: "#bbb" }}>- No Data Avaliable -</h1>
            </div>
          )}
        </CustomTabPanel>

        <CustomTabPanel value={selectedInfo} index={2}>
          <Shipments
            companyName={companySelect[0]?.companyName}
            shipments={shipments}
            country={companySelect[0]?.country}
          />
        </CustomTabPanel>

        {/* <CustomTabPanel value={selectedInfo} index={3}>
          {isLeadsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500,
              }}
            >
              {<Spinner size={120} />}
            </div>
          ) : (
            <>
              <Container>
                <MainFilter>
                  <div className="searchBy">
                    <MyItem
                      active={activeItem === "CompanyProfile"}
                      onClick={() => handleClick("CompanyProfile")}
                    >
                      Company Profile
                    </MyItem>
                    <MyItem
                      active={activeItem === "SearchContact"}
                      onClick={() => handleClick("SearchContact")}
                    >
                      Search Contact
                    </MyItem>
                    <MyItem
                      active={activeItem === "SearchList"}
                      onClick={() => handleClick("SearchList")}
                    >
                      Search List
                    </MyItem>
                  </div>
                  <div className="searchBy">
                    {activeItem === "SearchContact" && (
                      <>
                        <InRow>
                          <input
                            type="text"
                            placeholder="Employee Name*"
                            value={employeeName}
                            onChange={handleEmployeeName}
                          />
                          <Mr8 />

                          <input
                            type="text"
                            placeholder="Company name*"
                            value={companyName}
                            onChange={handleCompanyName}
                          />
                        </InRow>
                      </>
                    )}

                    {activeItem === "SearchList" && (
                      <input
                        type="text"
                        placeholder="List Name"
                        value={listName}
                        onChange={handleListName}
                      />
                    )}

                    {activeItem !== "CompanyProfile" && (
                      <SearchButton onClick={buscar}>Search</SearchButton>
                    )}
                  </div>
                </MainFilter>
              </Container>

              <Container>
                {activeItem === "CompanyProfile" && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <TravelExplore
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            Company Website:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.domain !== "Not Found" ? (
                              dataEmails?.domain
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <LinkedIn
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Linkedin:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.linkedin !== "Not Found" ? (
                              dataEmails?.linkedin
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                    </Grid>

                    <HrDefault />

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <FmdGood
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Address:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{
                              marginLeft: 10,
                              textTransform: "uppercase",
                            }}
                          >
                            {companySelect[0]?.country}{" "}
                            {companySelect[0]?.state
                              ? `| ${companySelect[0]?.state}`
                              : ""}{" "}
                            {companySelect[0]?.city
                              ? `| ${companySelect[0]?.city}`
                              : ""}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <YouTube
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Youtube:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.youtube !== "Not Found" ? (
                              dataEmails?.youtube
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                    </Grid>

                    <HrDefault />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <MonetizationOn
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Revenue:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            <i>Not Found</i>
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <Facebook
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Facebook:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.facebook !== "Not Found" ? (
                              dataEmails?.facebook
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                    </Grid>

                    <HrDefault />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <LocalPhone
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Phone:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.phones?.length > 0 ? (
                              dataEmails?.phones.map((phone, index) => (
                                <span key={index}>
                                  {phone}
                                  {index !== dataEmails?.phones.length - 1 &&
                                    ", "}
                                </span>
                              ))
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <IconTextContainer>
                          <Instagram
                            sx={{
                              fontSize: "1.4rem",
                              verticalAlign: "sub",
                              color: "#4b4b4b",
                              marginRight: "5px",
                            }}
                          />
                          <TextDefault size="18px" color="#4b4b4b">
                            {" "}
                            Instagram:
                          </TextDefault>
                          <TextDefault
                            size="18px"
                            color="#8a97aa"
                            bold="400"
                            style={{ marginLeft: 10 }}
                          >
                            {dataEmails?.instagram != "Not Found" ? (
                              dataEmails?.instagram
                            ) : (
                              <i>Not Found</i>
                            )}
                          </TextDefault>
                        </IconTextContainer>
                      </Grid>
                    </Grid>

                    <HrDefault />

                    <ColumnContainer style={{ marginTop: 50, gap: 24 }}>
                      <TextDefault
                        size="18px"
                        color="#17283E"
                        bold="500"
                        style={{ marginLeft: 10 }}
                      >
                        Company E-mails
                      </TextDefault>
                      <DataLead
                        data={dataEmails?.emails || []}
                        columns={[
                          {
                            field: "email",
                            headerName: "E-mail",
                            flex: 1,
                            editable: false,
                            valueGetter: (params) => params.row,
                          },
                          {
                            field: "confidence_level",
                            headerName: "Confidence Level",
                            flex: 1,
                            editable: false,
                            renderCell: () => (
                              <div
                                style={{
                                  padding: "0px 16px",
                                  background:
                                    dataEmails.confidence_level <= 75 &&
                                    dataEmails.confidence_level >= 50
                                      ? "#F9CC98"
                                      : dataEmails.confidence_level >= 75
                                      ? "#62CD95"
                                      : "#ED6161",
                                  borderRadius: 16,
                                  color: "#fff",
                                }}
                              >
                                <span>{dataEmails.confidence_level}%</span>
                              </div>
                            ),
                          },
                        ]}
                      />
                      <TextDefault
                        size="18px"
                        color="#17283E"
                        bold="500"
                        style={{ marginLeft: 10 }}
                      >
                        Linkedin Information
                      </TextDefault>
                      <DataLead
                        data={companyLinkedin?.employees || []}
                        columns={[
                          {
                            field: "name",
                            headerName: "Name",
                            flex: 1,
                            editable: false,
                          },
                          {
                            field: "title",
                            headerName: "Title",
                            flex: 1,
                            editable: false,
                          },
                          {
                            field: "linkedin_url",
                            headerName: "Linkedin url",
                            flex: 1,
                            editable: false,
                            renderCell: (params) => (
                              <a
                                href={params.value}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "inherit",
                                  textDecoration: "none",
                                }}
                              >
                                {params.value}
                              </a>
                            ),
                          },
                          {
                            field: "confidence",
                            headerName: "Confidence Level",
                            flex: 1,
                            editable: false,
                            renderCell: () => (
                              <div
                                style={{
                                  padding: "0px 16px",
                                  background:
                                    companyLinkedin.confidence_level <= 75 &&
                                    companyLinkedin.confidence_level >= 50
                                      ? "#F9CC98"
                                      : companyLinkedin.confidence_level >= 75
                                      ? "#62CD95"
                                      : "#ED6161",
                                  borderRadius: 16,
                                  color: "#fff",
                                }}
                              >
                                <span>{companyLinkedin.confidence_level}%</span>
                              </div>
                            ),
                          },
                        ]}
                        pageSize={10}
                      />
                    </ColumnContainer>
                  </>
                )}

                {showLinkedinEnrichment && activeItem === "SearchContact" && (
                  <>
                    <TextDefault color={"#17283E"} bold={"700"} size={"34px"}>
                      Linkedin Enrichment
                    </TextDefault>
                    {isLeadsLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 600,
                          width: "100dvw",
                        }}
                      >
                        {<Spinner size={120} />}
                      </div>
                    ) : (
                      <Container>
                        <LeadsHeader>
                          <img src={Logo} alt="Logo" />

                          <div className="head_title">
                            <h1>{employeeName}</h1>

                            <span>
                              {dataEmail?.job_title ? dataEmail?.job_title : ""}{" "}
                              at{" "}
                              {dataEmail?.job_company_name
                                ? dataEmail?.job_company_name
                                : ""}{" "}
                              at{" "}
                            </span>
                          </div>
                        </LeadsHeader>

                        <ContactInfo>
                          <div>
                            <LanguageOutlined sx={{ color: "#17283E" }} />
                            <strong>Company Website: </strong>{" "}
                            <span>
                              {companyName.replace(/\s/g, "").toLowerCase()}
                              .com
                            </span>
                          </div>
                          <div>
                            <LinkedIn sx={{ color: "#17283E" }} />
                            <strong>Linkedin: </strong>
                            <span>
                              {linkedin?.url != "Not Found" ? (
                                linkedin?.url
                              ) : (
                                <i>Not Found</i>
                              )}
                            </span>
                          </div>
                          <div>
                            <PhoneOutlined sx={{ color: "#17283E" }} />
                            <strong>Phone: </strong>{" "}
                            <span>
                              {dataEmail?.phones?.length > 0 ? (
                                dataEmail?.phones.map((phone, index) => (
                                  <span key={index}>
                                    {phone}
                                    {index !== dataEmail?.phones.length - 1 &&
                                      ", "}
                                  </span>
                                ))
                              ) : (
                                <i>Not Found</i>
                              )}
                            </span>
                          </div>
                          <div>
                            <Mail sx={{ color: "#17283E" }} />
                            <strong>Email: </strong>
                            <span>
                              {dataEmail?.emails?.length > 0 ? (
                                dataEmail?.emails.map((email, index) => (
                                  <span key={index}>
                                    {email}
                                    {index !== dataEmail?.emails.length - 1 &&
                                      ", "}
                                  </span>
                                ))
                              ) : (
                                <i>Not Found</i>
                              )}
                            </span>
                          </div>
                          <div>
                            <AssuredWorkloadOutlined
                              sx={{ color: "#17283E" }}
                            />
                            <strong>Confidence Level: </strong>{" "}
                            <span>{dataEmail?.confidence_level}</span>
                          </div>
                          <div>
                            <SearchButton
                              disabled={!dataArray.length}
                              style={{
                                backgroundColor: dataArray.length
                                  ? "#366DFB"
                                  : "#C4C4C4",
                                color: "#fff",
                              }}
                              onClick={() => {
                                OpenModalList();
                              }}
                            >
                              Add to list{" "}
                              <AddIcon
                                sx={{
                                  fontSize: "1rem",
                                  verticalAlign: "middle",
                                }}
                              />
                            </SearchButton>
                          </div>
                        </ContactInfo>
                      </Container>
                    )}
                    <ModalList
                      visible={isModalOpenList}
                      onCancel={CloseModalList}
                      userId={userId}
                      infos={dataArray}
                    />
                  </>
                )}

                {activeItem === "SearchList" && (
                  <Table
                    columns={columnsList}
                    dataSource={filteredData.length > 0 ? filteredData : lists}
                  />
                )}
                <ModalRenameList
                  visible={isModalOpen}
                  onCancel={CloseModal}
                  userId={userId}
                  listId={selectedListName}
                />
              </Container>
            </>
          )}
        </CustomTabPanel> */}

        <CustomTabPanel value={selectedInfo} index={4}>
          <>
            {sanctionStep === 0 ? (
              <SanctionContainer>
                <SanctionImage />

                <TextDefault bold={"400"} style={{ margin: "32px 0" }}>
                  Ensure the safety of your business by performing due diligence
                  and effectively screening business partners, <br /> potential
                  clients, and customers against 620+ global sanctions and
                  restricted party lists.
                </TextDefault>

                <ButtonBlue onClick={getSanctions}>Get Performance</ButtonBlue>
              </SanctionContainer>
            ) : (
              <>
                <CardFavoriteSanction />
                <br />
                <hr />
                <br />
                <CardSanction />
              </>
            )}
          </>
        </CustomTabPanel>

        <CustomTabPanel value={selectedInfo} index={5}>
          {Object.keys(companySelect[0]).length > 0 && (
            <Pdf
              ref={targetRef}
              type={supplierOrBuyer}
              company={companySelect}
              filters={{
                role: supplierOrBuyer,
                hsCode: hsCode,
                type: "byName",
                fromDate: fromDate,
                toDate: toDate,
              }}
              shipments={shipments}
            />
          )}
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
}
