import "./App.css";
import AppRoutes from "./routes/Routes";
import GlobalStyles from "./globalStyles";
import stripePromise from "./contexts/stripeApi";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider } from "styled-components";
import defaultTheme from "./assets/themes/default";

function App() {
  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <AppRoutes />
      </ThemeProvider>
    </Elements>
  );
}

export default App;
