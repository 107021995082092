import { Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CustomButton = styled(Button)`
  background-color: ${(props) => props.bgColor || "#a054fe"};
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`;

export const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  background-color: #a054fe;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 5px;
  }
`;

export const NewsContainer = styled.div`
  width: 85%;
  padding-left: 24px;
  height: 250px;

  .swiper {
    width: 100%;
    height: 250px;
  }

  .swiper-slide {
    width: 80%;
    max-width: 495px;
    height: 240px;
  }
`;
