import React, { useState } from "react";

import SecondPage from "./Pages/SecondPage/Index";
import ThirdPage from "./Pages/ThirdPage/Index";

import { Container } from "./styles";
import Cover from "./Pages/Cover/Index";

export const Pdf = React.forwardRef((props, ref) => {
  const [selectedCompany] = useState(props.companies);

  return (
    <Container ref={ref}>
      {!selectedCompany ? (
        <p>Loading...</p>
      ) : (
        <>
          <Cover />
          {props.dataType !== "Favorited" && (
            <SecondPage
              company={selectedCompany}
              generalData={props.generalData}
              general={props.general}
              toDate={props.toDate}
              fromDate={props.fromDate}
              role={props.role}
            />
          )}
          <ThirdPage
            company={selectedCompany}
            generalData={props.generalData}
            general={props.general}
            dataType={props.dataType}
            filterBy={props.filterBy}
            role={props.role}
            toDate={props.toDate}
            fromDate={props.fromDate}
          />
        </>
      )}
    </Container>
  );
});
