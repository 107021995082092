import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Slider, Grid } from "@mui/material";
import styled, { ThemeContext } from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import country from "../../utils/flag";

import IconSupplier from "../../../../icons/parcel.png";
import IconBuyer from "../../../../icons/investor.png";
import IconSupplierBlue from "../../../../icons/ParcelBlue.png";
import IconBuyerBlue from "../../../../icons/InvestorBlue.png";
import {
  AdvancedFilters,
  BtnNextSolutions,
  CardSwitch,
  CardSwitchText,
  Container,
  FilterFooter,
  MainFilter,
} from "./styles";
import {
  ImgDefault,
  RowContainer,
  TextDefault,
} from "../../../../assets/styles";
import { DatePickerInput } from "../../../../components/DatePickerInput";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Space } from "antd";

const PrettoSlider = styled(Slider)({
  color: "#366dfb",
  height: 8,
  alignSelf: "center",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "#366dfb",
  },
});

const ContainerShipment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  width: 100%;

  input {
    width: 100%;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #aaa;
    outline: none;
    margin-left: 4px;
  }
`;

const options = [
  { value: 500000, label: "$ 500.000" },
  { value: 1000000, label: "$ 1.000.000" },
  { value: 5000000, label: "$ 5.000.000" },
  { value: 10000000, label: "$ +10.000.000" },
  { value: 0, label: "All Companies" },
  { value: "custom", label: "Custom" },
];

function Option({ country }) {
  return (
    <div
      className="country-option"
      style={{ display: "flex", alignItems: "center" }}
    >
      <img src={country.src} alt={country.label} width={20} height={20} />
      <strong style={{ marginLeft: 12 }}>{country.label}</strong>
    </div>
  );
}

export default function Filters({
  toggleDrawer,
  animatedStep,
  moveStepAnimation,
  step,
  handleSelectTable,
  selectedTable,
  handleCountrySelect,
  countrySel,
  companyName,
  handleCompanyName,
  hsCodeSel,
  setHsCodeSel,
  products,
  supplierOrBuyer,
  fetchCompaniesByName,
  fetchCompanies,
  requestCompanies,
  cardSupplierOrBuyer,
  setFromDate,
  fromDate,
  setToDate,
  toDate,
  setDefaultRange,
  handleSwitchChange,
  switchValue,
}) {
  const theme = useContext(ThemeContext);
  const customSelectStyles = useMemo(
    () => ({
      valueContainer: (provided, state) => ({
        ...provided,
        maxWidth: "700px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        flexWrap: "nowrap",
      }),
      input: (provided, state) => ({
        ...provided,
        width: "50px",
      }),
    }),
    []
  );
  const correctHsCodes = products.map((hsCode) => {
    return {
      ...hsCode,
      value: `${hsCode.hsCode} - ${hsCode.hsName}`,
    };
  });

  const isButtonAbled = () => {
    if (selectedTable === "name") {
      return !!(countrySel !== "" && companyName !== "");
    }

    if (selectedTable === "hsCode") {
      return !!(supplierOrBuyer && hsCodeSel.length > 0 && countrySel !== "");
    }
  };

  // const saveRange = (event, newValue) => {
  //   setDefaultRange(newValue);
  // };

  // const saveRange = (event, newValue) => {
  //   if (value === 'custom') {
  //     console.log("aqui")
  //     setDefaultRange([customMinValue, customMaxValue]);
  //   } else {
  //     // Caso contrário, passamos apenas o novo valor
  //     setDefaultRange([0, value]);
  //   }
  // };

  const marks = [
    {
      value: 0,
      label: "US$ 0",
    },
    {
      value: 1000000,
      label: "US$ 1.000.000",
    },
    {
      value: 5000000,
      label: "US$ 5.000.000",
    },
    {
      value: 10000000,
      label: "+US$ 10.000.000",
    },
  ];

  const [value, setValue] = useState("");
  const [customMinValue, setCustomMinValue] = useState("");
  const [customMaxValue, setCustomMaxValue] = useState("");

  const handleChange = (selectedOption) => {
    setValue(selectedOption.value);
  };

  const handleCustomMinInputChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    setCustomMinValue(inputValue);
  };
  const handleCustomMaxInputChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    setCustomMaxValue(inputValue);
  };

  useEffect(() => {
    if (value === "custom") {
      setDefaultRange([customMinValue, customMaxValue]);
    } else if (value === 0) {
      setDefaultRange([]);
    } else {
      setDefaultRange([0, value]);
    }
  }, [value, customMinValue, customMaxValue]);

  // quando aprovado vou remover o filter range
  return (
    <Grid item xs={toggleDrawer ? 10 : 11} container className={animatedStep}>
      <Container>
        <RowContainer
          style={{
            width: 55,
            left: 80,
            marginTop: 48,
            alignItems: "center",
          }}
        >
          <button
            className="cursor-pointer duration-200 hover:scale-125 active:scale-100"
            title="Go Back"
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
              width: "100%",
              justifyContent: "flex-start",
              display: "flex",
            }}
            onClick={() => moveStepAnimation("back", step - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
              className="stroke-blue-300"
            >
              <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M11 6L5 12M5 12L11 18M5 12H19"
              ></path>
            </svg>
          </button>

          <h1
            style={{
              color: "#4B4B4B",
            }}
          >
            Filters
          </h1>
        </RowContainer>

        <MainFilter>
          <h2>Select an option</h2>

          <div className="Filters">
            <div className="role">
              <CardSwitch
                className="cardSwitch"
                color={
                  selectedTable === "hsCode"
                    ? theme.colors.main[500]
                    : theme.colors.light[100]
                }
                onClick={() => handleSelectTable("hsCode")}
              >
                <div className={"notiglow"} />
                <div className={"notiborderglow"} />

                <div className="text">
                  <CardSwitchText
                    className="notititle"
                    color={
                      selectedTable === "hsCode"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                  >
                    Hs Code
                  </CardSwitchText>
                  <CardSwitchText
                    className="notibody"
                    color={
                      selectedTable === "hsCode"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                  >
                    HsCodes they work with
                  </CardSwitchText>
                </div>
              </CardSwitch>

              <CardSwitch
                className="cardSwitch"
                color={
                  selectedTable === "name" ? theme.colors.main[500] : "#E9EDF8"
                }
                onClick={() => handleSelectTable("name")}
              >
                <div className={"notiglow"} />
                <div className={"notiborderglow"} />

                <div className="text">
                  <CardSwitchText
                    className="notititle"
                    color={
                      selectedTable === "name"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                  >
                    Company Name
                  </CardSwitchText>
                  <CardSwitchText
                    className="notibody"
                    color={
                      selectedTable === "name"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                  >
                    Based on the company name
                  </CardSwitchText>
                </div>
              </CardSwitch>
            </div>

            <div className="role">
              <CardSwitch
                className="cardSwitch"
                color={
                  supplierOrBuyer === "Supplier"
                    ? theme.colors.main[500]
                    : theme.colors.light[100]
                }
                onClick={() =>
                  selectedTable !== "name" && cardSupplierOrBuyer("Supplier")
                }
                ByName={selectedTable === "name"}
              >
                <div className={selectedTable === "name" ? "" : "notiglow"} />
                <div className={selectedTable ? "" : "notiborderglow"} />

                <div className="text">
                  <CardSwitchText
                    className="notititle"
                    ByName={selectedTable === "name"}
                    color={
                      supplierOrBuyer === "Supplier"
                        ? theme.colors.light[100]
                        : theme.colors.main[500]
                    }
                  >
                    Supplier
                  </CardSwitchText>

                  <CardSwitchText
                    className="notibody"
                    color={
                      supplierOrBuyer === "Supplier"
                        ? theme.colors.light[100]
                        : theme.colors.main[500]
                    }
                    ByName={selectedTable === "name"}
                  >
                    Find a supplier partner
                  </CardSwitchText>
                </div>

                <ImgDefault
                  width={"40px"}
                  height={"40px"}
                  style={{
                    zIndex: 9,
                    marginLeft: "80%",
                  }}
                  src={
                    supplierOrBuyer === "Supplier" || selectedTable === "name"
                      ? IconSupplier
                      : IconSupplierBlue
                  }
                />
              </CardSwitch>

              <CardSwitch
                className="cardSwitch"
                color={
                  supplierOrBuyer === "Buyer"
                    ? theme.colors.main[500]
                    : theme.colors.light[100]
                }
                onClick={() =>
                  selectedTable !== "name" && cardSupplierOrBuyer("Buyer")
                }
                ByName={selectedTable === "name"}
              >
                <div className={selectedTable === "name" ? "" : "notiglow"} />
                <div className={selectedTable ? "" : "notiborderglow"} />

                <div className="text">
                  <CardSwitchText
                    className="notititle"
                    color={
                      supplierOrBuyer === "Buyer"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                    ByName={selectedTable === "name"}
                  >
                    Buyer
                  </CardSwitchText>

                  <CardSwitchText
                    className="notibody"
                    color={
                      supplierOrBuyer === "Buyer"
                        ? "#E9EDF8"
                        : theme.colors.main[500]
                    }
                    ByName={selectedTable === "name"}
                  >
                    Find a client
                  </CardSwitchText>
                </div>

                <ImgDefault
                  width={"40px"}
                  height={"40px"}
                  style={{
                    zIndex: 9,
                    marginLeft: "80%",
                  }}
                  src={
                    supplierOrBuyer === "Buyer" || selectedTable === "name"
                      ? IconBuyer
                      : IconBuyerBlue
                  }
                />
              </CardSwitch>
            </div>
          </div>

          <div>
            {countrySel ? <small>Country</small> : <br />}
            <Select
              placeholder="Select the country"
              className="basic-select"
              classNamePrefix="Select a country"
              name="Country"
              defaultValue={countrySel}
              onChange={handleCountrySelect}
              value={country.country}
              options={country}
              isSearchable
              formatOptionLabel={(country) => <Option country={country} />}
            />
          </div>

          {selectedTable === "name" && (
            <div style={{ width: "100%" }}>
              {companyName ? (
                <>
                  <small> Company Name</small>
                  <br />
                </>
              ) : (
                <br />
              )}

              <input
                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={handleCompanyName}
                style={{ width: "100%" }}
              />
            </div>
          )}

          {selectedTable === "hsCode" && (
            <div style={{ width: "100%" }}>
              {hsCodeSel.length > 0 ? (
                <>
                  <small>Hs Codes</small>
                  <br />
                </>
              ) : (
                <br />
              )}
              <Select
                placeholder="Select 1 to 5 HsCodes"
                className="basic-multi-select"
                classNamePrefix="hsCodes"
                name="Hs Codes"
                defaultValue={hsCodeSel}
                onChange={setHsCodeSel}
                options={correctHsCodes}
                styles={customSelectStyles}
                isOptionDisabled={() => hsCodeSel.length >= 5}
                isSearchable
                isMulti
                formatOptionLabel={(hsCode) => (
                  <span className="Select-Text">
                    <strong>{hsCode.hsCode}</strong> -{" "}
                    <span>{hsCode.hsName}</span>
                  </span>
                )}
              />
            </div>
          )}
        </MainFilter>
        <br />

        <FilterFooter>
          <BtnNextSolutions
            className={isButtonAbled() ? "btnNextBlue" : "btnNextBlueLock"}
            onClick={() => {
              if (selectedTable === "name" && isButtonAbled()) {
                moveStepAnimation("next", 4);
                return fetchCompaniesByName();
              }

              if (selectedTable === "hsCode" && isButtonAbled()) {
                requestCompanies().then(() => {
                  return moveStepAnimation("next", 4);
                });
              }
            }}
            disabled={!isButtonAbled()}
          >
            <TextDefault size={"18px"} color={"#fff"}>
              Search
            </TextDefault>
            <ArrowForwardIcon
              style={{ marginLeft: 20 }}
              sx={{
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          </BtnNextSolutions>
        </FilterFooter>
      </Container>
    </Grid>
  );
}
