import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import { useLme } from "../../../hooks/getLME";

import { CustomButton } from "./styleD";

import { TextDefault } from "../../../assets/styles";
import InfoSelect from "../../findNewPartner/components/Selects";

const SplineChart = () => {
  const { lmeData, loading } = useLme();
  const [series, setSeries] = useState([]);
  const [chartTitle, setChartTitle] = useState("Aluminium");
  const [selectedCommodity, setSelectedCommodity] = useState({
    name: "Aluminium",
    color: "#3A8BDB",
  });
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCommodityClick = (commodityName, color) => {
    setSelectedCommodity({ name: commodityName, color });
    setSelectedItems([commodityName]);
  };

  const filteredSeries = series.filter(
    (serie) => serie.name === selectedCommodity.name
  );

  useEffect(() => {
    if (lmeData && lmeData?.MtUSD?.cashe_price) {
      const newSeries = lmeData?.MtUSD?.cashe_price.map((item) => ({
        name: item[0],
        data: item.slice(1).map((value) => parseFloat(value.replace(",", ""))),
      }));

      const seriesFormat = newSeries.map((serie) => ({
        ...serie,
        data: [...serie?.data],
      }));

      setSeries(seriesFormat);
    }
  }, [lmeData]);

  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="spline-chart">
      <TextDefault color={"#2D2D2D"} size={"18px"} bold={"600"}>
        {" "}
        London Metal Exchange{" "}
      </TextDefault>
      <br />
      <br />
      {window.innerWidth >= 800 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          <InfoSelect
            isSelected={selectedItems.includes("Aluminium")}
            onSelect={() => handleCommodityClick("Aluminium", "#3A8BDB")}
            type="Aluminium"
            iconType="lme"
          />
          {/* <InfoSelect
            isSelected={selectedItems.includes("Al Alloy")}
            onSelect={() => handleCommodityClick("Al Alloy", "#87B623")}
            type="Al Alloy"
            iconType="lme"
          />
          <InfoSelect
            isSelected={selectedItems.includes("NASAAC")}
            onSelect={() => handleCommodityClick("NASAAC", "#D9940E")}
            type="NASAAC"
            iconType="lme"
          /> */}
          <InfoSelect
            isSelected={selectedItems.includes("Copper")}
            onSelect={() => handleCommodityClick("Copper", "#E12E48")}
            type="Copper"
            iconType="lme"
          />
          <InfoSelect
            isSelected={selectedItems.includes("Lead")}
            onSelect={() => handleCommodityClick("Lead", "#775DD0")}
            type="Lead"
            iconType="lme"
          />
          <InfoSelect
            isSelected={selectedItems.includes("Nickel")}
            onSelect={() => handleCommodityClick("Nickel", "#B65E1E")}
            type="Nickel"
            iconType="lme"
          />
          <InfoSelect
            isSelected={selectedItems.includes("Tin")}
            onSelect={() => handleCommodityClick("Tin", "#6FA08E")}
            type="Tin"
            iconType="lme"
          />
          {/* <InfoSelect
            isSelected={selectedItems.includes("Cobalt")}
            onSelect={() => handleCommodityClick("Cobalt", "#5F8017")}
            type="Cobalt"
            iconType="lme"
          /> */}
          <InfoSelect
            isSelected={selectedItems.includes("Zinc")}
            onSelect={() => handleCommodityClick("Zinc", "#134185")}
            type="Zinc"
            iconType="lme"
          />
        </div>
      ) : (
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={16}
          style={{ padding: 16, width: "100%" }}
          modules={[Autoplay, Navigation]}
        >
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Aluminium")}
              onSelect={() => handleCommodityClick("Aluminium", "#3A8BDB")}
              type="Aluminium"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Al Alloy")}
              onSelect={() => handleCommodityClick("Al Alloy", "#87B623")}
              type="Al Alloy"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("NASAAC")}
              onSelect={() => handleCommodityClick("NASAAC", "#D9940E")}
              type="NASAAC"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Copper")}
              onSelect={() => handleCommodityClick("Copper", "#E12E48")}
              type="Copper"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Lead")}
              onSelect={() => handleCommodityClick("Lead", "#775DD0")}
              type="Lead"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Nickel")}
              onSelect={() => handleCommodityClick("Nickel", "#B65E1E")}
              type="Nickel"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Tin")}
              onSelect={() => handleCommodityClick("Tin", "#6FA08E")}
              type="Tin"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Cobalt")}
              onSelect={() => handleCommodityClick("Cobalt", "#5F8017")}
              type="Cobalt"
              iconType="lme"
            />
          </SwiperSlide>
          <SwiperSlide style={{ width: 80 }}>
            <InfoSelect
              isSelected={selectedItems.includes("Zinc")}
              onSelect={() => handleCommodityClick("Zinc", "#134185")}
              type="Zinc"
              iconType="lme"
            />
          </SwiperSlide>
        </Swiper>
      )}
      <ReactApexChart
        options={{
          ...options,
          colors: [selectedCommodity.color],
        }}
        series={filteredSeries}
        type="area"
        height={450}
      />
    </div>
  );
};

export default SplineChart;
