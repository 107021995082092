import styled from "styled-components";

export const Container = styled.main`
  h1 {
    color: #1d47b0;
  }

  header {
    margin-bottom: 48px;
  }

  button {
    border-radius: 17px;
    background: #246dec;
    display: flex;
    padding: 16px 8px;
    justify-content: center;
    align-items: center;
    color: #fafafa;
    font-weight: bold;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
    margin-left: 68px;

    &:hover {
      transform: scale(1.05);
      background: #3a8cf7;
    }

    &:active {
      transform: scale(0.95);
      background: #1c58d9;
    }
  }
`;

export const FormContainer = styled.section``;

export const ChartsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 68px;
`;
