import React from 'react';
import { Modal } from 'antd';
import CheckoutPage from '../../checkout';

const PurchaseModal = ({ visible, onCancel, priceId, userId, clientSecret, priceSponsor }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <CheckoutPage priceId={priceId} userId={userId} clientSecret={clientSecret} priceSponsor={priceSponsor} />
    </Modal>
  );
};

export default PurchaseModal;
