import React, { useState, useEffect } from "react";
import { Modal, Space } from "antd";
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import CodeIcon from '@mui/icons-material/Code';

const Warning = () => <WarningIcon style={{ color: "#F4CE14" }}/>;
const Dangerous = () => <DangerousIcon style={{ color: "#B31312" }} />;
const Logout = () => <LogoutIcon style={{ color: "#D83F31" }} />;
const CheckCircle = () => <CheckCircleIcon style={{ color: "#186F65" }} />;
const SearchOff = () => <SearchOffIcon  />;
const Code = () => <CodeIcon />;

const AllModal = ({ visible, onCancel, message, title, onConfirm, type }) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    switch (type) {
      case "danger":
        setIcon(<Dangerous />);
        break;
      case "logout":
        setIcon(<Logout />);
        break;
      case "warning":
        setIcon(<Warning />);
        break;
      case "correct":
        setIcon(<CheckCircle />);
        break;
      case "notFound":
        setIcon(<SearchOff />);
        break;
      default:
        setIcon(<Code />);
        break;
    }
  }, [type]);

  return (
    <Modal
      title={
        <Space>
          {icon}
          <span>{title}</span>
        </Space>
      }
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default AllModal;
