import React from "react";
import { Chip, ThemeProvider, createTheme } from "@mui/material";
import { TextDefault } from "../../../assets/styles";
import { TagColors } from "../styles";

const theme = createTheme();

export const TagInfo = ({ type }) => {
  let color;
  let label;

  switch (type) {
    case "MARKET COMMENTARY":
      color = "#4d01a6";
      label = "Market Commentary";
      break;
    case "ANALYSIS":
      color = "#4abc96";
      label = "Analysis";
      break;
    case "NEWS":
      color = "#a054fe";
      label = "News";
      break;
    default:
      color = "#feb005";
      label = "No Info";
  }

  return (
    <ThemeProvider theme={theme}>
      <TagColors color={color}>
        <TextDefault color="#fff">{label}</TextDefault>
      </TagColors>
    </ThemeProvider>
  );
};
