import axios from "axios";
import { APITSALE_KEY } from "./key";
import { format } from "date-fns";

export default function ProfileCompany(id, shipName, country, from, to) {
  return new Promise((resolve, reject) => {
    const lastYear = new Date().setFullYear(new Date().getFullYear() - 1);

    const body = JSON.stringify({
      id,
      shipName,
      country,
      fromDate:
        typeof from === "string" ? from : format(lastYear, "yyyy-MM-dd"),
      toDate: typeof to === "string" ? to : format(Date.now(), "yyyy-MM-dd"),
    });

    const header = {
      headers: {
        Authorization: "Bearer " + APITSALE_KEY.key,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    console.log(body);

    axios
      .post(
        "https://api.tsalemetals.com/t-api/v1/fullDataCompanies",
        body,
        header
      )
      .then((response) => {
        console.log("aqui");
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject("failed");
      });
  });
}
