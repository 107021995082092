import React, { useEffect, useLayoutEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

import {
  ArrowOpt,
  BalanceDrawer,
  CircleLogo,
  CircleLogoClose,
  ColumnContainer,
  CompleteMenu,
  DrawerModern,
  DrawerModernClose,
  IconOpt,
  ImgOpt,
  LogoDrawer,
  MenuDrawer,
  OptionsDrawer,
  RowContainer,
  TextDefault,
  TextSubMenu,
  TxtBalance,
  TxtOpt,
  WalletDrawer,
} from "../assets/styles";

import MenuIcon from "../icons/menu.png";
import MyIntelIcon from "../icons/folder.png";
import HomeIcon from "../icons/homeIcon.png";
import SettingsIcon from "../icons/setting.png";
import { authScreen, logout } from "../contexts/auth";
import getUserInfo from "../hooks/getUsers";
import { viewCredit } from "../hooks/credits";
import iconLogo from "../icons/T-SaleMetals-03.png";
import ResponsiveMapping from "./ResponsiveMapping";

const { size } = ResponsiveMapping();

export default function Drawer({ handleToggle, initState, isFixed }) {
  const [stateDrawer, useStateDrawer] = useState(initState);
  const [blurValues, setBlurValues] = useState(false);

  //Menu
  const [menuTI, setMenuTI] = useState(false);
  const [menuS, setMenuS] = useState(false);

  const navigate = useNavigate();
  function ModIsStateDrawer() {
    useStateDrawer(!stateDrawer);
    handleToggle(!stateDrawer);
  }

  const exit = () => {
    navigate("/");
    logout();
  };

  const [auth, setAuth] = useState(false);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const [credits, setCredits] = useState(null);

  useEffect(() => {
    if (userInfo) {
      const fetchCredits = async () => {
        try {
          const userCredits = await viewCredit(userInfo?.uid);
          setCredits(userCredits);
        } catch (error) {
          console.error("Erro ao buscar os créditos do usuário:", error);
        }
      };

      fetchCredits();
    }
  }, [userInfo]);

  const roleUser = userInfo?.userData?.role;
  return (
    <>
      {stateDrawer ? (
        <DrawerModern style={{ width: size === "sm" ? 220 : 250 }}>
          <header>
            <CircleLogo disabled={isFixed} onClick={ModIsStateDrawer}>
              <LogoDrawer src={iconLogo} />
            </CircleLogo>
            {/* <h2 style={{ color: "#fafafa", lineHeight: 0 }}>
                {userInfo?.userData?.name?.toUpperCase()}{" "}
                {userInfo?.userData?.lastName?.toUpperCase()}
              </h2> */}
            <RowContainer>
              <TextDefault color={"#fff"} size={"24px"}>
                T-Sale
              </TextDefault>
              <TextDefault color={"#fff"} size={"11px"} bold={"400"}>
                ®
              </TextDefault>
            </RowContainer>
          </header>

          <MenuDrawer>
            <OptionsDrawer
              noBeet={true}
              className="optDrewar"
              onClick={() => navigate("/home")}
            >
              <IconOpt>
                <ImgOpt src={HomeIcon} />
              </IconOpt>
              <TxtOpt>Home</TxtOpt>
            </OptionsDrawer>

            <OptionsDrawer
              className={menuTI ? "optDrewarOpen" : "optDrewar"}
              onClick={() => setMenuTI(!menuTI)}
            >
              <IconOpt>
                <ImgOpt src={MenuIcon} />
              </IconOpt>
              <TxtOpt>Trade Intel</TxtOpt>
              <ArrowOpt iconSt={menuTI} />
            </OptionsDrawer>
            {menuTI ? (
              <CompleteMenu>
                <TextSubMenu onClick={() => navigate("/find-new-partner")}>
                  Nexus Data
                </TextSubMenu>
                <TextSubMenu onClick={() => navigate("/simulation")}>
                  Simulation
                </TextSubMenu>
                <TextSubMenu onClick={() => navigate("/marketinteligence")}>
                  Market Inteligence
                </TextSubMenu>
                <TextSubMenu onClick={() => navigate("/stayinformed")}>
                  Highlights
                </TextSubMenu>                
              </CompleteMenu>
            ) : (
              ""
            )}

            <OptionsDrawer
              className="optDrewar"
              onClick={() => navigate("/saved-operations")}
            >
              <IconOpt>
                <ImgOpt src={MyIntelIcon} />
              </IconOpt>
              <TxtOpt>My Intel</TxtOpt>
              <ArrowOpt />
            </OptionsDrawer>

            <OptionsDrawer
              className={menuS ? "optDrewarOpen" : "optDrewar"}
              onClick={() => setMenuS(!menuS)}
            >
              <IconOpt>
                <ImgOpt src={SettingsIcon} />
              </IconOpt>
              <TxtOpt>Settings</TxtOpt>
              <ArrowOpt iconSt={menuS} />
            </OptionsDrawer>
            {menuS ? (
              <CompleteMenu>
                <TextSubMenu onClick={() => navigate("/profile")}>
                  Profile
                </TextSubMenu>
                <TextSubMenu onClick={() => navigate("/billing")}>
                  Billing
                </TextSubMenu>
                <TextSubMenu>Payment Method</TextSubMenu>
                <TextSubMenu>Subscription</TextSubMenu>
                <TextSubMenu>Add-on Credits and more</TextSubMenu>
                {roleUser === "admin" ? (
                  <TextSubMenu onClick={() => navigate("/analytics")}>
                    Analytics
                  </TextSubMenu>
                ) : (
                  <> </>
                )}
                <TextSubMenu onClick={exit}>Logout</TextSubMenu>
              </CompleteMenu>
            ) : (
              ""
            )}
          </MenuDrawer>

          <WalletDrawer>
            <TxtBalance
              size={"10px"}
              bold={"100"}
              style={{
                marginTop: 15,
                textAlign: "center",
                width: "85%",
                lineHeight: 1.3,
              }}
            >
              This is tour current balance, have credits every month with plans
              or add more as needed
            </TxtBalance>
            <BalanceDrawer
              className="balanceEffect"
              onClick={() => setBlurValues((prevState) => !prevState)}
            >
              <ColumnContainer
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 25,
                }}
              >
                <TxtBalance size={"10px"}>Your balance</TxtBalance>
                <TxtBalance isBlurred={blurValues} size={"17px"}>
                  {" "}
                  {credits} Credits
                </TxtBalance>
              </ColumnContainer>

              <div style={{ position: "absolute", right: "15px", top: "20px" }}>
                {blurValues ? (
                  <VisibilityIcon style={{ opacity: 0.3 }} />
                ) : (
                  <VisibilityOffIcon style={{ opacity: 0.3 }} />
                )}
              </div>
            </BalanceDrawer>
          </WalletDrawer>
        </DrawerModern>
      ) : (
        <DrawerModernClose>
          <CircleLogoClose onClick={ModIsStateDrawer}>
            <LogoDrawer src={iconLogo} size={"60px"} />
          </CircleLogoClose>
        </DrawerModernClose>
      )}
    </>
  );
}
