import React, { useEffect, useState } from "react";
import LoadingPage from "../../components/LoadingPage";
import { useNavigate } from "react-router-dom";
import { authScreen } from "../../contexts/auth";
import Drawer from "../../components/Drawer";
import { Grid } from "@mui/material";
import {
  BtnDefault,
  BtnNextSolutions,
  CardForm,
  ColumnContainer,
  ContainerBtnNext,
  ContainerBtnNextSolutions,
  GroupInput,
  ImgDefault,
  ImgOpt,
  InputDefault,
  TextDefault,
} from "../../assets/styles";
import IconEdit from "../../icons/edit-myicons.png";
import IconCompany from "../../icons/globe-myicons.png";
import IconTag from "../../icons/tag-myicons.png";
import IconAddress from "../../icons/address-myicons.png";
import IconCountry from "../../icons/country-myicons.png";
import IconPhone from "../../icons/phone-myicons.png";
import IconMail from "../../icons/mail.png";
import Sponsor from "../../icons/sponsorBanner.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconPremium from "../../icons/premiumIcon.png";
import IconCheck from "../../icons/check-myicons.png";

export default function CompanyProfile() {
  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  return (
    <>
      {auth ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={toggleDrawer ? 2 : 1}>
            <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
          </Grid>

          <Grid
            item
            xs={toggleDrawer ? 5 : 6}
            style={{ alignItems: "center", display: "flex" }}
          >
            <ColumnContainer>
              <ColumnContainer>
                <TextDefault color="#a054fe" size="25px" bold="800">
                  Update your company info
                </TextDefault>
                <TextDefault
                  color="#8a97aa"
                  size="16px"
                  bold="400"
                  style={{ marginTop: 10 }}
                >
                  My Company Profile
                </TextDefault>
              </ColumnContainer>

              <CardForm>
                <Grid container columnSpacing={4} xs={12}>
                  <Grid item xs={8}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconCompany} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Company Name"
                        type="text"
                        value={companyName}
                        onChange={(item) => setCompanyName(item.target.value)}
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                  <Grid item xs={4}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconTag} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Identification Number"
                        type="text"
                        value={identificationNumber}
                        onChange={(item) =>
                          setIdentificationNumber(item.target.value)
                        }
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                  <Grid item xs={8}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconAddress} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Address"
                        type="text"
                        value={address}
                        onChange={(item) => setAddress(item.target.value)}
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                  <Grid item xs={4}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconCountry} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Country"
                        type="text"
                        value={country}
                        onChange={(item) => setCountry(item.target.value)}
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                  <Grid item xs={6}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconPhone} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Business Email"
                        type="text"
                        value={businessEmail}
                        onChange={(item) => setBusinessEmail(item.target.value)}
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                  <Grid item xs={6}>
                    <GroupInput
                      style={{ marginBottom: 10 }}
                      className="groupInputSale"
                    >
                      <ImgOpt className="iconInputSale" src={IconMail} />
                      <InputDefault
                        className="inputSale"
                        placeholder="Business Contact"
                        type="text"
                        value={businessPhone}
                        onChange={(item) => setBusinessPhone(item.target.value)}
                      />
                      <ImgOpt className="iconInputHide" src={IconEdit} />
                    </GroupInput>
                  </Grid>
                </Grid>
              </CardForm>
            </ColumnContainer>
          </Grid>

          <Grid item xs={5}>
            <ColumnContainer
              style={{
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#a054fe",
              }}
            >
              <ImgDefault src={Sponsor} width="500px" height="450px" />
              <BtnDefault color="#224fad" hoverColor="#3667cc">
                <TextDefault
                  color="#ffff"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImgDefault
                    src={IconPremium}
                    width="20px"
                    height="20px"
                    style={{ marginRight: 10 }}
                  />
                  Add to my plan
                </TextDefault>
              </BtnDefault>
            </ColumnContainer>
          </Grid>

          <ContainerBtnNext>
            <BtnNextSolutions
              className={"btnNextWhite"}
              onClick={() => navigate("/my-company")}
            >
              <TextDefault size={"18px"} color={"#a054fe"}>
                Save
              </TextDefault>
              <ImgDefault
                src={IconCheck}
                width="20px"
                height="20px"
                style={{ marginLeft: 10 }}
              />
            </BtnNextSolutions>
          </ContainerBtnNext>
        </Grid>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
