import { styled } from "styled-components";

export const Container = styled.div``;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const Header = styled.header`
  margin-top: 36px;
  color: #1d47b0;
  margin-bottom: 64px;
`;

export const LogoIcon = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #a054fe;
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FormGroups = styled.section``;

export const PersonalData = styled.section`
  display: flex;

  & + & {
    margin-top: 64px;
  }

  header {
    max-width: 272px;
  }

  h2 {
    color: #1d47b0;
    line-height: 0px;
  }

  span {
    color: #bac4e7;
  }
`;

export const Data = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.div`
  font-size: 16px;
  position: relative;
  padding-top: 12px;

  & + & {
    margin-top: 24px;
  }

  input {
    padding-top: 8px;
    width: 100%;
    color: #1d408b;
    background-color: #fff;

    border: none;
    border-bottom: 2px solid #bac4e7;
    outline: none;
    font-size: 16px;
    min-width: 220px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;

    &:focus {
      border-bottom: 2px solid #a054fe;
    }

    ::placeholder {
      color: transparent;
    }
  }

  input:disabled {
    cursor: not-allowed;
  }

  label {
    pointer-events: none;
    position: absolute;
    color: #b0b0b0;
    top: 0;
    left: 0;
    margin-top: 13px;
    transition: 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
  }

  input:required:invalid + label {
    color: red;
  }

  input:focus:required:invalid + label:after {
    content: "*";
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    font-size: 12px;
    margin-top: 0;
    color: #a054fe;
  }
`;

export const Select = styled.div`
  font-size: 16px;
  position: relative;
  padding-top: 12px;
  margin-top: 24px;
  cursor: pointer;

  select {
    padding-top: 8px;
    width: 100%;
    cursor: pointer;
    color: #1d408b;
    font-weight: bold;

    border: none;
    border-bottom: 2px solid #bac4e7;
    outline: none;
    font-size: 16px;
    min-width: 220px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;

    &:focus {
      border-bottom: 2px solid #a054fe;
    }

    ::placeholder {
      color: transparent;
    }
  }

  select:disabled {
    cursor: not-allowed;
  }

  label {
    pointer-events: none;
    position: absolute;
    color: #b0b0b0;
    top: 0;
    left: 0;
    margin-top: 13px;
    transition: 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
  }

  select:required:invalid + label {
    color: red;
  }

  select:focus:required:invalid + label:after {
    content: "*";
  }

  select:focus + label,
  select:not(:placeholder-shown) + label {
    font-size: 12px;
    margin-top: 0;
    color: #a054fe;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  margin-top: 0;
  color: #fc5050;
`;

export const ActionsContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  button {
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }

  .save {
    background-color: #a054fe;
    font-size: 16px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;

    &:hover {
      background-color: #204cf0;
    }

    &:disabled {
      background-color: #bdd4ff;
    }
  }

  .cancel {
    background-color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    color: #888888;

    &:hover {
      color: #b0b0b0;
    }
  }
`;
