import React, { useState } from "react";
import {
  ContainerHome,
  TextDefault,
  IconServices,
  ColumnContainer,
} from "../../assets/styles";
import Drawer from "../../components/Drawer";
import { Grid } from "@mui/material";
import ButtonBlue from "../../components/myButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SustainabilityImg from "../../icons/sustainIcon.png";
import { Link } from "react-router-dom";

export default function Compliance() {
  const [toggleDrawer, useTroggleDawer] = useState(true);
  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }
  return (
    <ContainerHome>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={toggleDrawer ? 2 : 1}>
          <Drawer handleToggle={SetToggle} initState={toggleDrawer}/>
        </Grid>
        <Grid item xs={toggleDrawer ? 10 : 11} container alignItems="center">
          <Grid item xs={7}>
            <ColumnContainer style={{ marginLeft: 50 }}>
              <TextDefault color={"#4b4b4b"} size={"32px"}>
              Compliance
              </TextDefault>
              <TextDefault color={"#8a97aa"} size={"18px"} bold={'400'} style={{marginTop: 20, width: '75%'}}>
              Lorem Ipsum is simply dummy text of <br /> the printing and typesetting industry. <br />Lorem Ipsum has been the industry's <br />standard dummy...
              </TextDefault>
              <Link to={"/search-compliance"}>
                <ButtonBlue width="250px" marginTop="50px">
                  Get started{" "}
                  <ArrowForwardIcon
                    sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
                  />
                </ButtonBlue>
              </Link>
            </ColumnContainer>
          </Grid>
          <Grid item xs={4}>
            <IconServices iconUrl={SustainabilityImg}/>
          </Grid>
        </Grid>
      </Grid>
    </ContainerHome>
  );
}
