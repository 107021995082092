import styled from "styled-components";
const windowHeight = window.innerHeight;

export const Container = styled.main`
  width: 100%;
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr;
  grid-template-rows: 60px 200px 260px 140px 210px;
  column-gap: 28px;
  row-gap: 12px;
  margin-right: 16px;

  @media screen and (max-width: 1100px) {
    grid-template-rows: 60px 200px 260px 140px 210px 1fr;
  }
`;

export const Header = styled.header`
  grid-row: 1;
  grid-column: 1/-1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 28px;
  align-items: center;
`;

export const Notifications = styled.div`
  grid-row: 1;
  grid-column: 5;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  align-self: center;
`;

export const NotificationBtn = styled.div`
  width: 50px;
  height: 50px;
`;

export const FavoriteMetals = styled.section`
  grid-column: 1/5;
  flex-direction: row;
  display: flex;
  transition: 0.2s;
  margin-top: -20px;
  .swiper {
    height: 240px;
    transition: 0.2s;
  }

  .swiper-slide {
    height: 190px;
    transition: 0.2s;
  }

  @media screen and (max-width: 1100px) {
    grid-column: 1/-1;
  }
`;

export const DeleteMetalButton = styled.button`
  position: absolute;
  right: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 20;

  :hover {
    background-color: #d4dbf1;
    border-radius: 100%;
  }
`;

export const NewsContainer = styled.section`
  grid-column: 1/5;
  flex-direction: row;
  height: 160px;
  display: flex;
  transition: 0.2s;
  margin-top: -35px;

  .swiper {
    height: 160px;
    transition: 0.2s;
  }

  .swiper-slide {
    height: 120px;
    transition: 0.2s;
  }

  @media screen and (max-width: 1100px) {
    grid-column: 1/-1;
  }
`;

export const ServicesContainer = styled.section`
  grid-column: 1/5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  justify-content: center;
  height: 190px;
  transition: 0.2s;
  padding-left: 16px;
  margin-top: -30px;

  @media screen and (max-width: 1100px) {
    grid-column: 1/-1;
  }
`;

export const Service = styled.article`
  border-radius: 20px;
  border-width: 0px;
  display: flex;

  img {
    width: 95%;
    height: auto;
    max-height: 230px;
    border-width: 0px;
  }
`;

export const FavoriteCompaniesContainer = styled.section`
  grid-row: 2/4;
  grid-column: 5;
  background-color: #e9edf8;
  border-radius: 6px;
  padding: 15px 0px 0px 20px;
  transition: 0.2s;
  height: ${windowHeight - 115 + "px"};

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media screen and (max-width: 1100px) {
    grid-column: 1/-1;
    grid-row: 5;
  }
`;

export const FavoriteCompanies = styled.div`
  max-height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background: #e9edf8;
  }
`;

export const CardServiceMinimal = styled.div`
  background-color: #e9edf8;
  width: 93%;
  height: 90%;
  border-radius: 6px;
`;

export const SponsorContainer = styled.section`
  grid-row: 4 / -1;
  grid-column: 5;
  width: 100%;
  height: 320px;
  border-radius: 16px;
  border: 1px solid #5d5d5d40;
  cursor: pointer;
  transition: 0.2s;

  header {
    background-color: #a054fe;
    padding: 16px;
    border-radius: 16px 16px 0 0;

    img {
      width: 100%;
      height: auto;
      max-height: 140px;
    }
  }

  section {
    text-align: center;
    margin-bottom: 64px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
