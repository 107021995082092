import { database } from "../database/config";

export async function getMetalPrice() {
    try {
      const metalPriceRef = database.ref("MetalPrice");
      const snapshot = await metalPriceRef.once("value");
      const metalPriceData = snapshot.val();

      const metalObjectArray = [];
      if (metalPriceData) {
        Object.entries(metalPriceData).forEach(([metalName, metalInfo]) => {
          const metalObject = {
            MetalName: metalInfo[0][0], // Pega o valor da coluna 0
            value1: metalInfo[0][1],
            value2: metalInfo[0][2],
            value3: metalInfo[0][3],
            value4: metalInfo[0][4],
          };

          metalObjectArray.push(metalObject);
        });
      }
      return metalObjectArray;
    } catch (error) {
      throw new Error(
        "Erro ao obter dados da tabela MetalPrice: " + error.message
      );
    }
  }


  export async function getFavoriteMetals(userId) {
    try {
      const userRef = database.ref("Users").child(userId).child("FavoriteMetal");
      const snapshot = await userRef.once("value");
      const favoriteMetals = snapshot.val() || {};
      
      return favoriteMetals;
    } catch (error) {
      console.error("Error fetching favorite metals:", error);
      throw error;
    }
  }

    // Função para gerar um ID único
    function generateUniqueID() {
      return new Date().getTime().toString();
    }

 export async function addFavoriteMetal(data, userId) {
    const favoriteMetalRef = database.ref("Users").child(userId).child("FavoriteMetal");

    // Encontre a última chave numérica utilizada
    const snapshot = await favoriteMetalRef
      .orderByKey()
      .limitToLast(1)
      .once("value");

    let lastKeyNumber = 0;

    snapshot.forEach((childSnapshot) => {
      const lastKey = childSnapshot.key;
      lastKeyNumber = parseInt(lastKey.match(/\d+/)[0]);
    });

    const newKeyNumber = lastKeyNumber + 1;
    const newKey = `save${newKeyNumber}`;

    // Gerar um ID único para o novo favorito
    const newFavoriteId = generateUniqueID();

    // Adicionar o ID ao objeto de dados
    const newData = {
      ...data,
      id: newFavoriteId,
    };

    // Salvar os dados com a nova chave
    const newFavoriteMetalRef = favoriteMetalRef.child(newKey);
    await newFavoriteMetalRef.set({ data: newData });
  }

  export async function deleteFavoriteMetal(id, userId) {
    const favoriteMetalRef = database.ref("Users").child(userId).child("FavoriteMetal");

    // Encontre os nós com o MetalName correspondente
    const snapshot = await favoriteMetalRef.once("value");

    snapshot.forEach((childSnapshot) => {
      const nodeKey = childSnapshot.key;
      const nodeData = childSnapshot.val();

      if (nodeData.data && nodeData.data.id === id) {
        favoriteMetalRef.child(nodeKey).remove();
      }
    });
  }