import { useState, useEffect } from "react";

import useErrors from "../../hooks/useErrors";
import getUserInfo, {
  updateUser,
  changePassword,
  updateAddress,
} from "../../hooks/getUsers";

import isValidEmail from "./utils/isValidEmail";

export default function useProfile() {
  const [actualInfo, setActualInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [line1, setLine1] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");

  const [personalInfoChanged, setPersonalInfoChanged] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [AddressChanged, setAddressChanged] = useState(false);

  const { errors, setError, removeError } = useErrors();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const userInfo = await getUserInfo();
        setActualInfo(userInfo);
        setName(userInfo.userData?.name);
        setLastName(userInfo.userData?.lastName);
        setEmail(userInfo?.email);
        setPhone(userInfo.userData?.phone);
        setRole(userInfo.userData?.role || "Commercial");

        setCity(userInfo.userData?.address?.city);
        setCountry(userInfo.userData?.address?.country);
        setLine1(userInfo.userData?.address?.line1);
        setPostalCode(userInfo.userData?.address?.postalCode);
        setState(userInfo.userData?.address?.state);
      } catch (error) {
        console.error("Erro ao buscar informações do usuário:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Personal Info Inputs controls and Submit
  function handleChangeName(event) {
    setName(event.target.value);

    if (event.target.value !== actualInfo.userData.name) {
      setPersonalInfoChanged(true);
    } else {
      setPersonalInfoChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "name", message: "Insert a name!" });
    } else {
      removeError("name");
    }
  }

  function handleChangeLastName(event) {
    setLastName(event.target.value);

    if (event.target.value !== actualInfo.userData.lastName) {
      setPersonalInfoChanged(true);
    } else {
      setPersonalInfoChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "lastName", message: "Insert a last name!" });
    } else {
      removeError("lastName");
    }
  }

  function handleChangeEmail(event) {
    setEmail(event.target.value);

    if (event.target.value !== actualInfo?.email) {
      setPersonalInfoChanged(true);
    } else {
      setPersonalInfoChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "email", message: "Insert a e-mail!" });
    } else {
      removeError("lastName");
    }

    if (!isValidEmail(event.target.value)) {
      setError({ field: "email", message: "Insert a valid e-mail!" });
    } else {
      removeError("email");
    }
  }

  function handleChangePhone(event) {
    setPhone(event.target.value);

    if (event.target.value !== actualInfo?.userData?.phone) {
      setPersonalInfoChanged(true);
    } else {
      setPersonalInfoChanged(false);
    }

    if (event.target.value === "") {
      setError({ field: "phone", message: "Insert a phone!" });
    } else {
      removeError("phone");
    }
  }

  function handleChangeRole(event) {
    setRole(event.target.value);

    if (event.target.value !== actualInfo?.userData?.role) {
      setPersonalInfoChanged(true);
    } else {
      setPersonalInfoChanged(false);
    }
  }

  function handleCancelPersonal(event) {
    event.preventDefault();

    setName(actualInfo.userData.name || "");
    setLastName(actualInfo.userData.lastName || "");
    setEmail(actualInfo?.email || "");
    setPhone(actualInfo?.userData?.phone || "");
    setRole(actualInfo?.userData?.role);

    setPersonalInfoChanged(false);
  }

  function handleConfirmPersonal(event) {
    event.preventDefault();

    const info = {
      name,
      lastName,
      email,
      phone,
      role,
    };

    updateUser(actualInfo.uid, info);

    setPersonalInfoChanged(false);
  }

  // Change Password Inputs
  function handleActualPassword(event) {
    setActualPassword(event.target.value);

    if (event.target.value !== "") {
      setIsChangingPassword(true);
    } else {
      setIsChangingPassword(false);
    }
  }

  function handleNewPassword(event) {
    setNewPassword(event.target.value);

    if (event.target.value !== "") {
      setIsChangingPassword(true);
    } else {
      setIsChangingPassword(false);
    }
  }

  function handleConfirmPassword(event) {
    setConfirmNewPassword(event.target.value);

    if (event.target.value !== newPassword) {
      setError({
        field: "confirmPassword",
        message: "The passwords does not match",
      });
    } else {
      removeError("confirmPassword");
    }

    if (event.target.value !== "") {
      setIsChangingPassword(true);
    } else {
      setIsChangingPassword(false);
    }
  }

  function handleCancelChangePassword(event) {
    event.preventDefault();

    setActualPassword("");
    setNewPassword("");
    setConfirmNewPassword("");

    setIsChangingPassword(false);
  }

  function handleChangePassword(event) {
    event.preventDefault();

    changePassword(email, actualPassword, newPassword);

    setIsChangingPassword(false);
  }

  // Address Inputs controls
  function handleChangeCity(event) {
    setCity(event.target.value);

    if (event.target.value !== actualInfo.userData.city) {
      setAddressChanged(true);
    } else {
      setAddressChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "city", message: "Insert a city!" });
    } else {
      removeError("city");
    }
  }

  function handleChangeCountry(event) {
    setCountry(event.target.value);

    if (event.target.value !== actualInfo?.country) {
      setAddressChanged(true);
    } else {
      setAddressChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "country", message: "Insert a country!" });
    } else {
      removeError("country");
    }
  }

  function handleChangeLine1(event) {
    setLine1(event.target.value);

    if (event.target.value !== actualInfo?.userData?.line1) {
      setAddressChanged(true);
    } else {
      setAddressChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "line", message: "Insert a line 1!" });
    } else {
      removeError("line");
    }
  }

  function handleChangePostalCode(event) {
    setPostalCode(event.target.value);

    if (event.target.value !== actualInfo?.userData?.postalCode) {
      setAddressChanged(true);
    } else {
      setAddressChanged(false);
    }
  }

  function handleChangeState(event) {
    setState(event.target.value);

    if (event.target.value !== actualInfo?.userData?.state) {
      setAddressChanged(true);
    } else {
      setAddressChanged(false);
    }

    if (!event.target.value) {
      setError({ field: "state", message: "Insert a state!" });
    } else {
      removeError("state");
    }
  }

  function handleCancelAddress(event) {
    event.preventDefault();

    setCity(actualInfo.userData.city || "");
    setCountry(actualInfo?.country || "");
    setLine1(actualInfo?.userData?.line1 || "");
    setPostalCode(actualInfo?.userData?.postalCode || "");
    setState(actualInfo?.userData?.state || "");

    setAddressChanged(false);
  }

  function handleChangeAddress(event) {
    event.preventDefault();

    const info = {
      city,
      state,
      country,
      postalCode,
      line1,
    };

    updateAddress(actualInfo.uid, info);

    setAddressChanged(false);
  }

  return {
    isLoading,
    errors,

    name,
    lastName,
    email,
    phone,
    role,

    actualPassword,
    newPassword,
    confirmNewPassword,

    city,
    country,
    line1,
    postalCode,
    state,

    personalInfoChanged,
    handleChangeName,
    handleChangeLastName,
    handleChangePhone,
    handleChangeEmail,
    handleChangeRole,
    handleCancelPersonal,
    handleConfirmPersonal,

    isChangingPassword,
    handleActualPassword,
    handleNewPassword,
    handleConfirmPassword,
    handleCancelChangePassword,
    handleChangePassword,

    AddressChanged,
    handleChangeCity,
    handleChangeCountry,
    handleChangeLine1,
    handleChangePostalCode,
    handleChangeState,
    handleCancelAddress,
    handleChangeAddress,
  };
}
