import { database } from "../database/config";

export async function getPromotions() {
    try {
      const promotionsRef = database.ref("Promotions");
      const snapshot = await promotionsRef.once("value");
      const promotions = snapshot.val();

      return promotions;
    } catch (error) {
      throw new Error("Erro ao obter dados das promoções: " + error.message);
    }
  }