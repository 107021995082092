import React, { useEffect, useState, useRef } from "react";
import {
  ColumnContainer,
  ContainerHome,
  TextDefault,
  IconServices,
} from "../../assets/styles";
import Drawer from "../../components/Drawer";
import { Grid } from "@mui/material";
import ButtonBlue from "../../components/myButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MetalPrice from "../../icons/MetalPrice1.png";
import { Link, useNavigate } from "react-router-dom";
import { authScreen } from "../../contexts/auth";
import LoadingPage from "../../components/LoadingPage";
import getUserInfo from "../../hooks/getUsers";
import { Divider, Tour } from "antd";
import Dashboard from "./imgs/dashboard.png";
import SearchM from "./imgs/metalprice.png";
import SearchL from "./imgs/lme.png";
import SavedM from "./imgs/savedMetalprice.png";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

export default function MetalPriceLme() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }

  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const [open, setOpen] = useState(false);
  const steps = [
    {
      title: "Dashboard",
      description: "",
      cover: <img alt="tour.png" src={Dashboard} />,
      target: () => ref1.current,
    },
    {
      title: "Market Inteligence",
      description: "Analyze metal prices.",
      target: () => ref2.current,
      cover: <img alt="tour.png" src={SearchM} />,
    },
    {
      title: "LME",
      description: "Choice and click for analyze LME and obtain quotes.",
      cover: <img alt="tour.png" src={SearchL} />,
      target: () => ref3.current,
    },
    {
      title: "Your metal prices saved",
      description: "When you save some metal price, they will show up here.",
      cover: <img alt="tour.png" src={SavedM} style={{ height: "350px" }} />,
      target: () => ref4.current,
    },
  ];

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            style={{
              height: "100%",
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <Grid item xs={toggleDrawer ? 8 : 8} container alignItems="center">
              <Grid container xs={12}>
                <Grid item xs={7}>
                  <ColumnContainer style={{ width: "100%", marginLeft: 100 }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <TextDefault color={"#4b4b4b"} size={"32px"}>
                      Market Inteligence
                    </TextDefault>
                    <TextDefault
                      color={"#8a97aa"}
                      size={"18px"}
                      bold={"400"}
                      style={{ marginTop: 20, width: "75%" }}
                    >
                      On this page you can search for any metal and view the
                      prices of the last three days
                    </TextDefault>
                    <Link to={"/marketinteligence-dashboard"}>
                      <ButtonBlue width="250px" marginTop="50px" ref={ref1}>
                        Get started{" "}
                        <ArrowForwardIcon
                          sx={{ fontSize: "1.2rem", verticalAlign: "middle" }}
                        />
                      </ButtonBlue>
                    </Link>
                    <>
                      <ButtonBlue
                        width="250px"
                        marginTop="12px"
                        onClick={() => setOpen(true)}
                      >
                        Begin Tour{" "}
                        <ConnectingAirportsIcon
                          sx={{ fontSize: "1.4rem", verticalAlign: "middle" }}
                        />
                      </ButtonBlue>

                      <Divider />

                      <Tour
                        open={open}
                        onClose={() => setOpen(false)}
                        steps={steps}
                      />
                    </>
                  </ColumnContainer>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: 200 }}>
                  <IconServices iconUrl={MetalPrice} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
