import React, { useState } from "react";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid, Paper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import Mock from "./mock.json";
import StarIcon from "@mui/icons-material/Star";
export default function ResultCompliance() {
  const { companyName } = useParams();
  const [toggleDrawer, useTroggleDawer] = useState(true);
  const data = Mock;
  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }

  return (
    <ContainerHome>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={toggleDrawer ? 2 : 1}>
          <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
        </Grid>

        <Grid item xs={10}>
          <div style={{ marginLeft: "50px", marginTop: "20px" }}>
            <TextDefault color={"#4b4b4b"} size={"32px"}>
              <AssuredWorkloadIcon /> Result - {companyName}
            </TextDefault>
            <br />
            <TextDefault
              color={"#8A97AA"}
              size={"14px"}
              style={{ width: "50%" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the <br />
              industry's standard dummy...
            </TextDefault>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
              marginLeft: "55px",
              width: "80%",
            }}
          >
            <Grid container spacing={2}>
              {Object.keys(data).map((key) => {
                const item = data[key];
                return (
                  <Grid item xs={12} sm={6} md={4} lg={12} key={key}>
                    <Paper
                      style={{
                        backgroundColor: "#1b3065",
                        padding: "20px",
                        color: "#ffffff",
                      }}
                    >
                      <Typography variant="h6">
                        List Name: {item.listName}
                      </Typography>
                      <Typography variant="body2">
                        Matched Entity Name: {item.matchedEntityName}
                      </Typography>
                      <Typography variant="body2">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Match Score: {item.matchScore}{" "}
                          {item.matchScore > 50 ? (
                            <StarIcon style={{ color: "yellow" }} />
                          ) : null}
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        Effective Date: {item.details.effectiveDate}
                      </Typography>
                      <Typography variant="body2">
                        Notes:{" "}
                        {item.details.notes.map((note, index) => (
                          <span key={index}>
                            {index > 0 && <br />}
                            {note}
                          </span>
                        ))}
                      </Typography>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </ContainerHome>
  );
}
