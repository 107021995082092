import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  border-radius: 6px;
  background-color: ${(props) => props.color ? props.color : '#4d01a6'};
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: ${(props) => (props.width ? props.width : 'auto')};
  margin-top: ${(props) => props.marginTop || '0'};
  margin-left: ${(props) => props.marginLeft || '0'};
  height: 45px;
  font-size: 20px;
  
  &:hover {
    background-color: ${(props) => props.colorHover ? props.colorHover : '#280849'};
  }
`;

const ButtonBlue = ({ children, onClick, width, marginTop, marginLeft, color, colorHover }) => {
  return <StyledButton onClick={onClick} width={width} marginTop={marginTop} marginLeft={marginLeft} color={color} colorHover={colorHover}>{children}</StyledButton>;
};

export default ButtonBlue;
