import React, { useEffect, useRef, useState } from "react";
import { ContainerHome } from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Button, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "../../../components/LoadingPage";
import { authScreen } from "../../../contexts/auth";
import {
  addFavoriteMetal,
  deleteFavoriteMetal,
  getFavoriteMetals,
  getMetalPrice,
} from "../../../hooks/metalPrice";
import LineChart from "../components/chart";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import getUserInfo from "../../../hooks/getUsers";
import { deleteCredit, historyCredits } from "../../../hooks/credits";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../components/pdfExport";
import { saveAnalytics } from "../../../hooks/analytics";

export default function MetalPriceDetails() {
  const [data, setData] = useState([]);
  const [toggleDrawer, useTroggleDawer] = useState(true);
  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteMetals, setFavoriteMetals] = useState([]);

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  useEffect(() => {
    getMetalPrice().then((res) => setData(Object.values(res)));
  }, []);

  function convertToNumber(item) {
    const convertedItem = { ...item };

    if (item.value1 !== undefined) {
      convertedItem.value1 = item.value1.trim()
        ? parseFloat(item.value1.replace(",", "."))
        : 0;
    } else {
      convertedItem.value1 = 0;
    }

    if (item.value2 !== undefined) {
      convertedItem.value2 = item.value2.trim()
        ? parseFloat(item.value2.replace(",", "."))
        : 0;
    } else {
      convertedItem.value2 = 0;
    }

    if (item.value3 !== undefined) {
      convertedItem.value3 = item.value3.trim()
        ? parseFloat(item.value3.replace(",", "."))
        : 0;
    } else {
      convertedItem.value3 = 0;
    }

    if (item.value4 !== undefined) {
      convertedItem.value4 = item.value4.trim()
        ? parseFloat(item.value4.replace(",", "."))
        : 0;
    } else {
      convertedItem.value4 = 0;
    }

    return convertedItem;
  }

  const convertedItem = convertToNumber(item);

  useEffect(() => {
    if (userInfo?.uid === "") {
      return;
    }

    async function fetchMetals() {
      getFavoriteMetals(userInfo?.uid)
        .then((res) => {
          for (const key in res) {
            if (res[key].data.MetalName === item.MetalName) {
              setIsFavorite(true);
              return;
            }
          }
        })
        .catch((err) => console.log(err));
    }

    fetchMetals();
  }, [userInfo, item]);

  const allMetal = {};

  for (const favoriteKey in favoriteMetals) {
    const favoriteData = favoriteMetals[favoriteKey].data;

    if (favoriteData.MetalName === item.MetalName) {
      allMetal.id = favoriteData.id;
      break;
    }
  }

  const id = allMetal.id;

  const formattedDate = new Date().toISOString();

  const custCredit = 1;
  const infoC = {
    text: `You used credits with research in Market Inteligence`,
    type: "decrease",
    date: formattedDate,
    credits: custCredit,
  };


  const infoA = {
    action: `Favorite`,
    date: formattedDate,
    page: 'Metal Price Details',
    keywords: `${item?.MetalName}`,
    name: userInfo?.userData?.name
  };

  function handleFavorite() {
    setIsFavorite((prevState) => !prevState);

    if (isFavorite) {
      deleteFavoriteMetal(id, userInfo?.uid);
    } else {
      saveAnalytics(userInfo?.uid, infoA);;
      deleteCredit(userInfo?.uid, custCredit);
      historyCredits(infoC, userInfo?.uid);
      addFavoriteMetal(item, userInfo?.uid);
    }
  }
  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={2}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <div style={{ marginLeft: "80px" }} ref={pdfRef}>
              <Typography sx={{ marginTop: "60px" }}>
                {" "}
                {item?.MetalName}{" "}
              </Typography>
              <PdfExport action={handlePrint} />
              <Typography variant="caption" sx={{ color: "#4B4B4B" }}>
                Phasellus vitae ipsum in lectus gravida eleifend. Phasellus vel
                erat bibendum
              </Typography>
              <LineChart data={convertedItem} />
              <Button
                variant="outlined"
                style={{
                  borderColor: "#24438f",
                  borderRadius: "20px",
                  color: "#24438f",
                }}
                onClick={handleFavorite}
              >
                {isFavorite ? "Remove as a favorite" : "Save as a favorite"}
                {isFavorite ? (
                  <StarIcon style={{ color: "#F99417" }} />
                ) : (
                  <StarOutlineIcon style={{ color: "#F99417" }} />
                )}
              </Button>
            </div>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
