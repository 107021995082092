import React, { useEffect, useState } from "react";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { ContainerHome, TextDefault } from "../../../assets/styles";
import { Grid } from "@mui/material";
import Drawer from "../../../components/Drawer";
import LoadingPage from "../../../components/LoadingPage";
import getUserInfo from "../../../hooks/getUsers";
import PricingCard from "../components/princingCredits";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Credits = () => {
  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const navigate = useNavigate();

  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  const handleGoBack = () => {
    window.history.back(); 
  };

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            <Grid marginLeft={toggleDrawer ? "70px" : ""} marginTop="30px">
              <TextDefault color={"#4b4b4b"} size={"32px"}>
              <ArrowBackIcon
                  onClick={handleGoBack}
                  style={{
                    cursor: "pointer",
                  }}
                />{" "}
                   Get more credits
              </TextDefault>
                <PricingCard credits={50} pay={89.9} unitPrice="1,19" userInfo={userInfo} />
                <PricingCard credits={100} pay={169.9} unitPrice="1,19" userInfo={userInfo} />
                <PricingCard credits={200} pay={219.9} unitPrice="1,09"  userInfo={userInfo}/>
                <PricingCard credits={300} pay={319.9} unitPrice="1,06" userInfo={userInfo} />
                <PricingCard credits={500} pay={489.9} unitPrice="0,97" userInfo={userInfo} />
          </Grid>
        </Grid>
      </ContainerHome>
    ) : (
      <LoadingPage />
    )}
  </>
)
    }
export default Credits;
