import { database } from "../database/config";

export async function getSessionId() {
    try {
      const ref = database.ref('T-API/key/sessionid-TradeMo');
  
      const snapshot = await ref.once('value');
      const sessionId = snapshot.val();
      return sessionId;
    } catch (error) {
      console.error('Erro ao recuperar sessionid-TradeMo:', error);
      throw error;
    }
  }

  export async function saveCompliance(userId, data) {
    database.ref(`Users/${userId}/MyCompliance`).push(data)
      .then(() => {
        console.log('Dados salvos com sucesso no Firebase!');
      })
      .catch((error) => {
        console.error('Erro ao salvar os dados:', error);
      });
  }

  export async function getCompliance(userId) {
    try {
      const userRef = database.ref("Users").child(userId).child("MyCompliance");
      const snapshot = await userRef.once("value");
      const favoriteCompliance = snapshot.val() || {};
      
      return favoriteCompliance;
    } catch (error) {
      console.error("Error fetching favorite compliance:", error);
      throw error;
    }
  }