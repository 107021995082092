import { database, firestore } from "../database/config";

export async function getCompanieFavorite(id) {
  return new Promise(async (resolve, reject) => {
    let companies = [];
    await database
      .ref("Users")
      .child(id)
      .child("FavoriteCompanies")
      .once("value")
      .then(async (snapshot) => {
        const childKeys = Object.keys(snapshot.val());
        await firestore
          .collection("ListofCompanies")
          .orderBy("companyId")
          .where("companyId", "in", childKeys)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot) {
              querySnapshot.forEach((doc) => {
                companies.push(doc.data());
              });
              resolve(companies);
            }
          })
          .catch((err) => {
            reject("Erro ao buscar as empresa, detalhes: " + err);
          });
      })
      .catch((err) => {
        reject("Erro ao buscar os favoritos, detalhes: " + err);
      });
  });
}
