import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import country from "../../../components/Flag";
import Capitalize from "../../../utils/capitalize";
import { format } from "date-fns";

//Modelo de teste para tabela

export default function DataGridDemo({ ...props }) {
  const textStyle = { color: "#4D6484", fontWeight: "bold", cursor: "pointer" };

  function formatarNumeroEmDolar(numero) {
    if (numero > 0) {
      numero = parseFloat(numero.toString().replace(".", ""));
      return numero.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return "-";
    }
  }

  const columns = [
    {
      field: "country",
      headerName: "Country",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt={params.row.country}
            src={renderFlag(params.row.country)}
            style={{ width: 40, height: 40, marginLeft: 10 }}
          />
          <p style={{ marginLeft: 10, fontWeight: 400 }}>
            {Capitalize(params.row.country)}
          </p>
        </div>
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <p style={{ fontWeight: 400 }}>{Capitalize(params.row.companyName)}</p>
      ),
    },
    props.dataType === "New" &&
      props.filterBy === "hsCode" && {
        field: "Shipment Value",
        headerName: "Shipment Value (USD)",
        flex: 1,
        editable: false,
        valueGetter: (params) => params.row.shipmentValue,
        renderCell: (params) => (
          <p style={{ fontWeight: 400 }}>
            {formatarNumeroEmDolar(params.row.shipmentValue)}
          </p>
        ),
        sortComparator: (v1, v2) => v1 - v2,
      },
    props.dataType === "New"
      ? {
          field: "companyRole",
          headerName: "Role",
          editable: false,
          width: 140,
          renderCell: (params) => (
            <p style={{ fontWeight: 400 }}>{props.role}</p>
          ),
        }
      : {
          field: "savedAt",
          headerName: "Saved At",
          editable: false,
          flex: 1,
          renderCell: (params) => (
            <p style={{ fontWeight: 400 }}>
              {params.row?.savedAt
                ? format(new Date(params.row?.savedAt), "MM/dd/yyyy")
                : "Not Informed"}
            </p>
          ),
        },
    {
      field: "action",
      headerName: "",
      editable: false,
      width: 110,
      renderCell: (params) => (
        <span
          style={{
            fontWeight: 400,
            color: "#366DFB",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Visit Profile
        </span>
      ),
    },
  ];

  const renderFlag = (countryName) => {
    const filterCountry = country.filter(
      (obj) => obj.country.toLocaleLowerCase() === countryName
    );
    return filterCountry[0].src;
  };

  const getCustomRowId = (row) => {
    if (row?.companyId) {
      return row.companyId;
    }

    return row.id;
  };

  return (
    <Box sx={{ height: "100%", width: "98%", marginTop: 4 }}>
      <DataGrid
        style={textStyle}
        rows={props.data}
        columns={columns}
        hideFooter
        hideFooterPagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: "savedAt", sort: "desc" }],
          },
        }}
        pageSizeOptions={[5]}
        density="standard"
        getRowId={getCustomRowId}
        onRowClick={(params) =>
          window.open(
            `/find-new-partner?companyName=${params.row.companyName}&companyId=${params.id}&country=${params.row.country}&role=${props.role}&isFavorited=${props.dataType}`,
            "_blank"
          )
        }
      />
    </Box>
  );
}
