import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'

let firebaseConfig = {
  apiKey: "AIzaSyBgQ95mRqtfNSv40T0au3-tq7wrbRuU2qc",
  authDomain: "t-sale-metals.firebaseapp.com",
  databaseURL: "https://t-sale-metals-default-rtdb.firebaseio.com",
  projectId: "t-sale-metals",
  storageBucket: "t-sale-metals.appspot.com",
  messagingSenderId: "591858572678",
  appId: "1:591858572678:web:982a2aa2e3de5fb397e007",
  measurementId: "G-R6JD3Z4LP1",
  storageBucket: "gs://t-sale-metals.appspot.com",
  };
 
if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}
export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();
