import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getAllAnalyticsData } from "../../../hooks/analytics";

const UserChart = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    getAllAnalyticsData()
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Erro ao obter dados de Analytics:", error);
      });
  }, []);

  const countNames = () => {
    const rows = Object.keys(data).reduce((acc, key) => {
      const innerValues = Object.values(data[key]);
      acc.push(...innerValues);
      return acc;
    }, []);

    const nameCount = rows.reduce((acc, row) => {
      const name = row.name;
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    return nameCount;
  };

  const nameCount = countNames();

  const labels = Object.keys(nameCount);
  const series = Object.values(nameCount);

  const chartData = {
    series,
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              label: "Total",
              formatter: function () {
                return series.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
      labels,
      title: {
        text: "Users",
        align: "center",
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="radialBar"
        width="400"
      />
    </div>
  );
};

export default UserChart;
