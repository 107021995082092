import React from "react";
import PDF from "../../../icons/pdf-file.png";
import CSV from "../../../icons/x-file.png";
import { BookmarkAdd, DirectionsBoat } from "@mui/icons-material";

export default function ExportBtn({ action, saveOp, exportSimu, step, excel }) {
  return (
    <div style={{ position: "absolute", right: 50, top: -30 }}>
      <ul className="wrapper">
        <li className="icon twitter" onClick={() => action("AQUILOG")}>
          <span className="tooltip">PDF</span>
          <span>
            <i className="fab fa-twitter"></i>
          </span>
          <img src={PDF} style={{ width: 25, height: 25 }} alt="PDF Icon" />
        </li>

        {step !== 4 && (
          <>
            <li className="icon twitter" onClick={excel}>
              <span className="tooltip">Excel</span>
              <span>
                <i className="fab fa-twitter"></i>
              </span>
              <img
                src={CSV}
                style={{ width: 25, height: 25 }}
                alt="Excel Icon"
              />
            </li>
           {/* <li className="icon instagram" onClick={saveOp}>
              <span className="tooltip">Save operation</span>
              <span>
                <i className="fab fa-instagram"></i>
              </span>
              <BookmarkAdd sx={{ color: "#17283E" }} />
        </li>*/}

            <li className="icon instagram" onClick={exportSimu}>
              <span className="tooltip">Create a simulation</span>
              <span>
                <i className="fab fa-instagram"></i>
              </span>
              <DirectionsBoat sx={{ color: "#17283E" }} />
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
