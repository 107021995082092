import React, { useEffect, useState } from "react";
import { authScreen } from "../../contexts/auth";
import { Grid } from "@mui/material";
import LoadingPage from "../../components/LoadingPage";
import { useNavigate } from "react-router-dom";
import Drawer from "../../components/Drawer";
import {
  BtnDefault,
  CardCompany,
  CircleDefault,
  ColumnContainer,
  IconHistory,
  ImgDefault,
  RowContainer,
  TextDefault,
} from "../../assets/styles";
import RegisterCompanyIcon from "../../icons/edit.png";
import MyProductsIcon from "../../icons/boxCBlue.png";
import ChatIcon from "../../icons/chat.png";
import ChartMixed from "./components/ChartMixed";
import RadialBar from "./components/RadialBar";

import LocationIcon from "../../icons/ping-v2.png";
import ContactIcon from "../../icons/smallPhone.png";
import MailIcon from "../../icons/mail.png";
import HistIcon from "../../icons/history.png";

export default function MyCompany() {
  const [auth, setAuth] = useState(false);
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  return (
    <>
      {auth ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={toggleDrawer ? 2 : 1}>
            <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
          </Grid>

          <Grid item xs={toggleDrawer ? 10 : 11}>
            <ColumnContainer style={{ marginTop: 40 }}>
              <TextDefault color="#4b4b4b" size="35px">
                My Company
              </TextDefault>
              <TextDefault
                color="#8a97aa"
                size="16px"
                style={{ marginTop: 10 }}
              >
                Dashboard
              </TextDefault>
            </ColumnContainer>

            <Grid
              container
              columnSpacing={4}
              xs={12}
              style={{ marginTop: 40, height: "86%" }}
            >
              <Grid item xs={9}>
                <Grid item xs={12}>
                  <CardCompany height="410px">
                    <RowContainer style={{ width: "100%", padding: 20 }}>
                      <ChartMixed />
                    </RowContainer>
                  </CardCompany>
                </Grid>
                <Grid
                  container
                  xs={12}
                  style={{ marginTop: "2%", height: 320 }}
                >
                  <Grid container xs={8}>
                    <Grid container xs={12} spacing={3}>
                      <Grid item xs={4}>
                        <CardCompany color="#a054fe" height="88%">
                          <ColumnContainer>
                            <RowContainer
                              style={{
                                marginTop: 20,
                                alignItems: "center",
                                marginLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              <ColumnContainer>
                                <CircleDefault size={65}>
                                  <ImgDefault
                                    src={RegisterCompanyIcon}
                                    width={"30px"}
                                    height={"30px"}
                                  />
                                </CircleDefault>
                              </ColumnContainer>
                              <TextDefault
                                color="#fff"
                                size="20px"
                                style={{ marginLeft: 20 }}
                              >
                                Company Profile
                              </TextDefault>
                            </RowContainer>
                            <TextDefault
                              color="#fff"
                              bold="400"
                              size="12px"
                              style={{
                                marginTop: 10,
                                marginLeft: 20,
                                height: 50,
                              }}
                            >
                              Check and update your company information here!
                            </TextDefault>
                            <BtnDefault
                              width="50%"
                              color="#002c67"
                              style={{ marginTop: 20, marginLeft: 20 }}
                              onClick={() => navigate("/my-company/profile")}
                            >
                              <TextDefault color="#fff">Check</TextDefault>
                            </BtnDefault>
                          </ColumnContainer>
                        </CardCompany>
                      </Grid>
                      <Grid item xs={4}>
                        <CardCompany color="#a054fe" height="88%">
                          <ColumnContainer>
                            <RowContainer
                              style={{
                                marginTop: 20,
                                alignItems: "center",
                                marginLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              <ColumnContainer>
                                <CircleDefault size={65}>
                                  <ImgDefault
                                    src={MyProductsIcon}
                                    width={"30px"}
                                    height={"30px"}
                                  />
                                </CircleDefault>
                              </ColumnContainer>
                              <TextDefault
                                color="#fff"
                                size="20px"
                                style={{ marginLeft: 20 }}
                              >
                                My Product
                              </TextDefault>
                            </RowContainer>
                            <TextDefault
                              color="#fff"
                              bold="400"
                              size="12px"
                              style={{
                                marginTop: 10,
                                marginLeft: 20,
                                height: 50,
                              }}
                            >
                              Add new products and edit those already
                              registered.
                            </TextDefault>
                            <BtnDefault
                              width="50%"
                              color="#002c67"
                              style={{ marginTop: 20, marginLeft: 20 }}
                            >
                              <TextDefault color="#fff">Open</TextDefault>
                            </BtnDefault>
                          </ColumnContainer>
                        </CardCompany>
                      </Grid>
                      <Grid item xs={4}>
                        <CardCompany color="#a054fe" height="88%">
                          <ColumnContainer>
                            <RowContainer
                              style={{
                                marginTop: 20,
                                alignItems: "center",
                                marginLeft: 20,
                                paddingRight: 20,
                              }}
                            >
                              <ColumnContainer>
                                <CircleDefault size={65}>
                                  <ImgDefault
                                    src={ChatIcon}
                                    width={"30px"}
                                    height={"30px"}
                                  />
                                </CircleDefault>
                              </ColumnContainer>
                              <TextDefault
                                color="#fff"
                                size="20px"
                                style={{ marginLeft: 20 }}
                              >
                                Corporate Chat
                              </TextDefault>
                            </RowContainer>
                            <TextDefault
                              color="#fff"
                              bold="400"
                              size="12px"
                              style={{
                                marginTop: 10,
                                marginLeft: 20,
                                height: 50,
                              }}
                            >
                              Contact your customers or suppliers.
                            </TextDefault>
                            <BtnDefault
                              width="50%"
                              color="#002c67"
                              style={{ marginTop: 20, marginLeft: 20 }}
                              //onClick={() => navigate('/my-company/chats')}
                            >
                              <TextDefault color="#fff">Message</TextDefault>
                            </BtnDefault>
                          </ColumnContainer>
                        </CardCompany>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      xs={12}
                      style={{ marginTop: "0.3%" }}
                    >
                      <Grid item xs={12}>
                        <CardCompany color="#a054fe"></CardCompany>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={4}>
                    <CardCompany>
                      <ColumnContainer style={{ width: "100%" }}>
                        <RowContainer
                          style={{
                            width: "100%",
                            backgroundColor: "#002c67",
                            height: "40%",
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TextDefault color="#fff" size="22px">
                            T-Sale Metals®
                          </TextDefault>
                        </RowContainer>
                        <ColumnContainer>
                          <RowContainer
                            style={{
                              alignItems: "center",
                              marginTop: 20,
                              marginLeft: 10,
                            }}
                          >
                            <ImgDefault
                              src={LocationIcon}
                              width="25px"
                              height="25px"
                            />
                            <TextDefault
                              color="#4b4b4b"
                              size="20px"
                              style={{ marginLeft: 10 }}
                            >
                              123 Main Street, Chicago, IL 60601
                            </TextDefault>
                          </RowContainer>
                          <RowContainer
                            style={{
                              alignItems: "center",
                              marginTop: 20,
                              marginLeft: 10,
                            }}
                          >
                            <ImgDefault
                              src={MailIcon}
                              width="25px"
                              height="25px"
                            />
                            <ColumnContainer>
                              <TextDefault
                                color="#4b4b4b"
                                size="20px"
                                style={{ marginLeft: 10 }}
                              >
                                tondini@tsalemetals.com
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="15px"
                                style={{ marginTop: 2, marginLeft: 10 }}
                              >
                                Business Email
                              </TextDefault>
                            </ColumnContainer>
                          </RowContainer>
                          <RowContainer
                            style={{
                              alignItems: "center",
                              marginTop: 20,
                              marginLeft: 10,
                            }}
                          >
                            <ImgDefault
                              src={ContactIcon}
                              width="25px"
                              height="25px"
                            />
                            <ColumnContainer>
                              <TextDefault
                                color="#4b4b4b"
                                size="20px"
                                style={{ marginLeft: 10 }}
                              >
                                +1 (424) 555-0198
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="15px"
                                style={{ marginTop: 2, marginLeft: 10 }}
                              >
                                Business Contact
                              </TextDefault>
                            </ColumnContainer>
                          </RowContainer>
                          <TextDefault
                            color="#a054fe"
                            size="18px"
                            bold={"800"}
                            style={{
                              marginTop: 20,
                              marginLeft: 15,
                              marginBottom: 15,
                              cursor: "pointer",
                            }}
                          >
                            Active Sponsor Plan
                          </TextDefault>
                        </ColumnContainer>
                      </ColumnContainer>
                    </CardCompany>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <CardCompany height="410px">
                      <ColumnContainer
                        style={{
                          paddingTop: 30,
                          paddingLeft: 30,
                          width: "100%",
                        }}
                      >
                        <TextDefault color="#4b4b4b" size="25px">
                          Search history
                        </TextDefault>

                        <RowContainer
                          style={{ marginTop: 20, alignItems: "center" }}
                        >
                          <IconHistory>
                            <ImgDefault
                              src={HistIcon}
                              width="30px"
                              height="30px"
                            />
                          </IconHistory>

                          <RowContainer
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "75%",
                            }}
                          >
                            <ColumnContainer style={{ marginLeft: 20 }}>
                              <TextDefault color="#4b4b4b" size="20px">
                                Iron
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="12px"
                                style={{ marginTop: 5 }}
                              >
                                2023/12/12
                              </TextDefault>
                              <TextDefault color="#8a97aa" size="12px">
                                United State
                              </TextDefault>
                            </ColumnContainer>

                            <RowContainer>
                              <TextDefault color="#4b4b4b" size="18px">
                                1,000.00 USD
                              </TextDefault>
                            </RowContainer>
                          </RowContainer>
                        </RowContainer>
                        <RowContainer
                          style={{ marginTop: 20, alignItems: "center" }}
                        >
                          <IconHistory>
                            <ImgDefault
                              src={HistIcon}
                              width="30px"
                              height="30px"
                            />
                          </IconHistory>

                          <RowContainer
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "75%",
                            }}
                          >
                            <ColumnContainer style={{ marginLeft: 20 }}>
                              <TextDefault color="#4b4b4b" size="20px">
                                Scrap
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="12px"
                                style={{ marginTop: 5 }}
                              >
                                2023/12/15
                              </TextDefault>
                              <TextDefault color="#8a97aa" size="12px">
                                Canada
                              </TextDefault>
                            </ColumnContainer>

                            <RowContainer>
                              <TextDefault color="#4b4b4b" size="18px">
                                990.00 USD
                              </TextDefault>
                            </RowContainer>
                          </RowContainer>
                        </RowContainer>
                        <RowContainer
                          style={{ marginTop: 20, alignItems: "center" }}
                        >
                          <IconHistory>
                            <ImgDefault
                              src={HistIcon}
                              width="30px"
                              height="30px"
                            />
                          </IconHistory>

                          <RowContainer
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "75%",
                            }}
                          >
                            <ColumnContainer style={{ marginLeft: 20 }}>
                              <TextDefault color="#4b4b4b" size="20px">
                                Aluiminum
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="12px"
                                style={{ marginTop: 5 }}
                              >
                                2023/12/16
                              </TextDefault>
                              <TextDefault color="#8a97aa" size="12px">
                                Germany
                              </TextDefault>
                            </ColumnContainer>

                            <RowContainer>
                              <TextDefault color="#4b4b4b" size="18px">
                                3,000.00 USD
                              </TextDefault>
                            </RowContainer>
                          </RowContainer>
                        </RowContainer>
                        <RowContainer
                          style={{ marginTop: 20, alignItems: "center" }}
                        >
                          <IconHistory>
                            <ImgDefault
                              src={HistIcon}
                              width="30px"
                              height="30px"
                            />
                          </IconHistory>

                          <RowContainer
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "75%",
                            }}
                          >
                            <ColumnContainer style={{ marginLeft: 20 }}>
                              <TextDefault color="#4b4b4b" size="20px">
                                Zinc
                              </TextDefault>
                              <TextDefault
                                color="#8a97aa"
                                size="12px"
                                style={{ marginTop: 5 }}
                              >
                                2023/12/17
                              </TextDefault>
                              <TextDefault color="#8a97aa" size="12px">
                                Brazil
                              </TextDefault>
                            </ColumnContainer>

                            <RowContainer>
                              <TextDefault color="#4b4b4b" size="18px">
                                6,200.00 USD
                              </TextDefault>
                            </RowContainer>
                          </RowContainer>
                        </RowContainer>
                      </ColumnContainer>
                    </CardCompany>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "6.5%" }}>
                    <CardCompany height={"318px"}>
                      <RowContainer
                        style={{
                          width: "100%",
                          paddingTop: 20,
                          paddingLeft: 20,
                        }}
                      >
                        <RadialBar />
                      </RowContainer>
                    </CardCompany>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
