/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCompanieFavorite } from "../../../../hooks/findNewPartner";
import { authScreen } from "../../../../contexts/auth";
import getUserInfo from "../../../../hooks/getUsers";
import ProfileCompany from "../../../../service/ProfileCompany";

import CompanyMapperInfo from "../../../home/utils/mapper";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Empty } from "./styles";
import MagnifierQuestion from "./assets/magnifier-question.svg";

import {
  ColumnContainer,
  ContainerHome,
  TextDefault,
  RowContainer,
  ImgDefault,
} from "../../../../assets/styles";
import Drawer from "../../../../components/Drawer";
import { Grid } from "@mui/material";

import LoadingPage from "../../../../components/LoadingPage";

import "./styles.css";
import ExportBtn from "../components/ExportBtn";

import Capitalize from "../../../../utils/capitalize";
import PingV2 from "../../../../icons/ping-v2.png";
import RoleIcon from "../../../../icons/pass.png";
import CardProfile from "../components/CardProfile";
import BoatIcon from "../../../../icons/boatv2.png";
import Deal from "../../../../icons/deal.png";
import Loading from "../components/loading";
import CardSmHorizontal from "../components/CardSmHorizontal";
import Country from "../../../../components/Flag";
import DataGrid from "../components/DataGrid";

import "swiper/css";
import "swiper/css/navigation";
import { useReactToPrint } from "react-to-print";

export default function Fnp() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState([]);
  const [auth, setAuth] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [companySelect, setCompanySelect] = useState();
  const [step, setStep] = useState(4);

  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [animatedStep, setAnimatedStep] = useState("staticStep");
  const [products, setProducts] = useState([]);

  const pdfProfileRef = useRef(null);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
  }

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const moveStepAnimation = (animation, step) => {
    //animation next = Direita para Esquerda
    //animation back = Esqueda pra direita
    //step = condicional da tela de exibição
    scrollTop();

    if (animation == "next") {
      setAnimatedStep("nextStepAnimated");
      setStep(step);
      setTimeout(() => {
        setAnimatedStep("staticStep");
      }, 1000);
    } else {
      setAnimatedStep("backStepAnimated");
      setStep(step);
      setTimeout(() => {
        setAnimatedStep("staticStep");
      }, 1000);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 4000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  }, [navigate]);

  const dataCompany = (id, info) => {
    let getData = info.filter((obj) => {
      return obj.id === id;
    });
    setStep(5);
    if (temShipments(getData)) {
      setCompanySelect(getData);

      setStep(6);
    } else {
      setCompanySelect(getData);
      ProfileCompany(getData[0].id, getData[0].companyName, getData[0].country)
        .then((resp) => {
          setStep(6);
          let array = Object.assign({}, getData[0], resp.profile, {
            shipments: resp.shipment,
          });
          setCompanySelect([array]);
          console.log("profile API Format");
          console.log([array]);
        })
        .catch((err) => {
          console.log("Erro na requisição profile id: " + getData[0].companyId);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (auth) {
      const fetchDataC = async () => {
        try {
          const dataUnmapped = await getCompanieFavorite(userInfo.uid);
          const mappedData = CompanyMapperInfo(dataUnmapped);

          setCompanies(mappedData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataC();
    }
  }, [auth, userInfo]);

  function temShipments(array) {
    return array.some((objeto) => objeto.hasOwnProperty("shipments"));
  }

  const renderFlag = (countryName) => {
    const filterCountry = Country.filter(
      (obj) => obj.country.toLocaleLowerCase() === countryName
    );
    if (filterCountry.length === 0) {
      const flagDefault = Country.filter(
        (obj) => obj.country.toLocaleLowerCase() === "flag"
      );
      return flagDefault[0].src;
    } else {
      return filterCountry[0].src;
    }
  };

  const listHsCodeCompany = (array) => {
    let string;
    console.log({ array });
    array.forEach((obj) => {
      if (string) {
        string = string + `, ${obj}`;
      } else {
        string = obj;
      }
    });

    return string;
  };

  const handlePrintProfile = useReactToPrint({
    content: () => pdfProfileRef.current,
  });

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>
            {step === 4 ? (
              <>
                <Grid
                  item
                  xs={toggleDrawer ? 10 : 11}
                  container
                  className={animatedStep}
                >
                  <Grid item xs={12}>
                    <ColumnContainer style={{ marginLeft: 20, marginTop: 50 }}>
                      <RowContainer
                        style={{
                          width: 55,
                          position: "fixed",
                          left: "5%",
                          top: 40,
                        }}
                      >
                        <button
                          class="cursor-pointer duration-200 hover:scale-125 active:scale-100"
                          title="Go Back"
                          style={{
                            backgroundColor: "transparent",
                            borderWidth: 0,
                            width: "100%",
                            justifyContent: "flex-start",
                            display: "flex",
                          }}
                          onClick={() => navigate("/saved-operations")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50px"
                            height="50px"
                            viewBox="0 0 24 24"
                            className="stroke-blue-300"
                          >
                            <path
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M11 6L5 12M5 12L11 18M5 12H19"
                            ></path>
                          </svg>
                        </button>
                      </RowContainer>
                      <TextDefault color={"#4b4b4b"} size={"32px"}>
                        Result
                      </TextDefault>
                      <TextDefault
                        color={"#8a97aa"}
                        size={"18px"}
                        bold={"400"}
                        style={{ marginTop: 10, width: "75%" }}
                      >
                        Favorited companies
                      </TextDefault>

                      {companies?.length > 0 && (
                        <div>
                          <DataGrid data={companies} clickRow={dataCompany} />
                        </div>
                      )}

                      {companies?.length === 0 && (
                        <Empty>
                          <img
                            src={MagnifierQuestion}
                            alt="Magnifier Question"
                          />
                          <h2>
                            Looks like you don't have saved any company yet,
                            click the button below to save your first one
                          </h2>

                          <button
                            onClick={() => navigate("/find-new-partners")}
                          >
                            Save first
                          </button>
                        </Empty>
                      )}
                    </ColumnContainer>
                  </Grid>
                </Grid>

                <div style={{ height: 10, width: "100%" }} />
              </>
            ) : step === 5 ? (
              <ColumnContainer
                style={{
                  width: "90%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Loading />
              </ColumnContainer>
            ) : step === 6 ? (
              <Grid
                item
                xs={toggleDrawer ? 10 : 11}
                container
                className={animatedStep}
                ref={pdfProfileRef}
              >
                <Grid item xs={12} style={{ marginBottom: 50 }}>
                  <ColumnContainer style={{ marginLeft: 20, marginTop: 50 }}>
                    <RowContainer
                      style={{
                        width: 55,
                        position: "fixed",
                        left: "5%",
                        top: 40,
                      }}
                    >
                      <button
                        className="cursor-pointer duration-200 hover:scale-125 active:scale-100"
                        title="Go Back"
                        style={{
                          backgroundColor: "transparent",
                          borderWidth: 0,
                          width: "100%",
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                        onClick={() => moveStepAnimation("back", 4)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50px"
                          height="50px"
                          viewBox="0 0 24 24"
                          className="stroke-blue-300"
                        >
                          <path
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            d="M11 6L5 12M5 12L11 18M5 12H19"
                          ></path>
                        </svg>
                      </button>
                    </RowContainer>
                    <RowContainer style={{ alignItems: "center" }}>
                      <TextDefault color={"#4b4b4b"} size={"32px"}>
                        {Capitalize(companySelect[0]?.companyName)}
                      </TextDefault>
                      <ImgDefault
                        src={renderFlag(companySelect[0].country)}
                        style={{ marginLeft: 20 }}
                        width={"40px"}
                        height={"40px"}
                      />
                      <ExportBtn action={handlePrintProfile} />
                    </RowContainer>
                    {companySelect[0].addressList !== "" ? (
                      <RowContainer
                        style={{ alignItems: "center", marginTop: 10 }}
                      >
                        <ImgDefault
                          src={PingV2}
                          width={"18px"}
                          height={"18px"}
                        />
                        <TextDefault
                          color={"#8a97aa"}
                          size={"16px"}
                          bold={"400"}
                          style={{ width: "75%", marginLeft: 5 }}
                        >
                          {Capitalize(
                            (companySelect[0].address ||
                              companySelect[0].state) +
                              ", " +
                              companySelect[0].country
                          )}
                        </TextDefault>
                      </RowContainer>
                    ) : (
                      <RowContainer
                        style={{ alignItems: "center", marginTop: 10 }}
                      >
                        <ImgDefault
                          src={RoleIcon}
                          width={"18px"}
                          height={"18px"}
                        />
                        <TextDefault
                          color={"#8a97aa"}
                          size={"16px"}
                          bold={"400"}
                          style={{ width: "75%", marginLeft: 5 }}
                        >
                          {Capitalize(
                            companySelect[0].role === undefined
                              ? `Supplier, ${companySelect[0].country}`
                              : companySelect[0].role +
                                  ", " +
                                  companySelect[0].country
                          )}
                        </TextDefault>
                      </RowContainer>
                    )}
                    <Grid
                      container
                      rowSpacing={1}
                      xs={12}
                      style={{ marginTop: 50 }}
                    >
                      <Grid item xs={2}>
                        <CardProfile
                          title={"Number Of Shipments"}
                          value={
                            companySelect[0].totalShipmentsImportedCount +
                            companySelect[0].totalShipmentsExportedCount
                          }
                          src={BoatIcon}
                        />
                      </Grid>
                      {/* <Grid item xs={2}>
                        <CardProfile
                          title={"Shipment Value"}
                          value={`US$ ${numberReducer(
                            companySelect[0].shipmentValue
                          )}`}
                          src={ShipValue}
                        />
                      </Grid> */}
                      <Grid item xs={2}>
                        <CardProfile
                          title={"Trading Partner"}
                          value={
                            companySelect[0].importTradingPartnerCount +
                            companySelect[0].exportTradingPartnerCount
                          }
                          src={Deal}
                        />
                      </Grid>
                    </Grid>
                  </ColumnContainer>

                  {/**Countries Exporting To*/}
                  {companySelect[0].countriesExportingTo.length > 0 ? (
                    <>
                      <ColumnContainer
                        style={{
                          marginTop: 70,
                          marginLeft: 20,
                          marginBottom: 8,
                        }}
                      >
                        <TextDefault size={"24px"} color={"#4b4b4b"}>
                          Countries Exporting To
                        </TextDefault>
                        <TextDefault color={"#8a97aa"} bold={"400"}>
                          Top Destinations
                        </TextDefault>
                      </ColumnContainer>

                      <Swiper
                        spaceBetween={28}
                        slidesPerView={"auto"}
                        navigation
                        modules={[Navigation]}
                        style={{
                          width: "97%",
                        }}
                      >
                        {companySelect[0].countriesExportingTo.map((item) => {
                          return (
                            <SwiperSlide
                              key={item.country}
                              style={{ width: 280 }}
                            >
                              <Grid item>
                                <CardSmHorizontal
                                  data={item}
                                  flag={renderFlag(item.country)}
                                />
                              </Grid>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </>
                  ) : (
                    ""
                  )}

                  {/**Countries Importing From*/}
                  {companySelect[0].countriesImportingFrom.length > 0 ? (
                    <>
                      <ColumnContainer
                        style={{
                          marginTop: 35,
                          marginLeft: 20,
                          marginBottom: 8,
                        }}
                      >
                        <TextDefault size={"24px"} color={"#4b4b4b"}>
                          Countries Importing From
                        </TextDefault>
                        <TextDefault color={"#8a97aa"} bold={"400"}>
                          The Top Exporting Nations
                        </TextDefault>
                      </ColumnContainer>

                      <Swiper
                        spaceBetween={28}
                        slidesPerView={"auto"}
                        navigation
                        modules={[Navigation]}
                        style={{
                          width: "97%",
                        }}
                      >
                        {companySelect[0].countriesImportingFrom.map((item) => {
                          return (
                            <SwiperSlide
                              key={item.country}
                              style={{ width: 280 }}
                            >
                              <Grid item>
                                <CardSmHorizontal
                                  data={item}
                                  flag={renderFlag(item.country)}
                                />
                              </Grid>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </>
                  ) : (
                    ""
                  )}

                  {/**Export Trading Partners*/}
                  {companySelect[0].exportTradingPartners.length > 0 ? (
                    <>
                      <ColumnContainer
                        style={{
                          marginTop: 35,
                          marginLeft: 20,
                          marginBottom: 8,
                        }}
                      >
                        <TextDefault size={"24px"} color={"#4b4b4b"}>
                          Export Trading Partners
                        </TextDefault>
                        <TextDefault color={"#8a97aa"} bold={"400"}>
                          Top Trading Partners
                        </TextDefault>
                      </ColumnContainer>

                      <Swiper
                        spaceBetween={28}
                        slidesPerView={"auto"}
                        navigation
                        modules={[Navigation]}
                        style={{
                          width: "97%",
                        }}
                      >
                        {companySelect[0].exportTradingPartners.map((item) => {
                          return (
                            <SwiperSlide
                              key={item.companyName}
                              style={{ width: 280 }}
                            >
                              <Grid item key={item.companyName}>
                                <CardSmHorizontal
                                  data={item}
                                  flag={renderFlag(item.country)}
                                  txt1={Capitalize(item.companyName)}
                                />
                              </Grid>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
