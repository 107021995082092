import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";

const styles = {
  customButton: {
    backgroundColor: "#a054fe",
    color: "#fff",
    borderRadius: 12,
    "&:hover": {
      backgroundColor: "#2449AA",
    },
  },
};

function SquareModal({ open, closeModal }) {
  const handleClose = () => {
    closeModal();
  };

  const redirectToDashboard = () => {
    window.location.href = "/dashboard-sustainability";
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        className="centered-content"
        style={{
          width: "300px",
          borderRadius: "20px",
          backgroundColor: "#fff",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleIcon style={{ color: "#a054fe", fontSize: 48 }} />
        <Typography style={{ color: "#a054fe" }} variant="h5">
          Answers saved
        </Typography>
        <Typography
          style={{ color: "black", textAlign: "center" }}
          variant="body2"
        >
          You can change them whenever <br /> you want.
        </Typography>
        <br />
        <Button style={styles.customButton} onClick={redirectToDashboard}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default SquareModal;
