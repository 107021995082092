import {database} from '../database/config'

export default async function HsCodeList() {
    return new Promise(async(resolve, reject) => {
        let products = []
        await database.ref("AllProduct").once("value", (snapshot) => {
            snapshot.forEach((item) => {
                let data = {
                key: item.key,
                hsCode: item.val().hsCode,
                hsName: item.val().hsName,
                };
                products.push(data);
            });
        });
        if(products.length > 0) {
            resolve(products)
        } else {
            reject('empty')
        }
    })
}
