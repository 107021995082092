import styled from "styled-components";

export const Empty = styled.div`
  height: 80dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    width: 40%;
    color: #1b2e8d;
  }

  img {
    width: 80px;
  }

  button {
    background-color: #246dec;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fafafa;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #3a8cf7;
    }

    &:active {
      background-color: #1c57d9;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-bottom: 4px;

  button {
    font-size: 16;
    font-weight: bold;
    color: #fafafa;
    background: #1c57d9;
    padding: 12px 24px;
    border: none;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
      background: #246dec;
    }

    :active {
      background: #1d47b0;
    }
  }
`;

export const Container = styled.main`
  width: 100%;
  height: 80dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TabsContainer = styled.section`
  width: 97%;
  height: fit-content;
  border-radius: 8px;
  transition: all 0.2s;
`;

export const FilterByName = styled.div`
  flex-direction: row;
  display: flex;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 16px;

  input {
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #aaa;
    outline: none;
  }
`;

export const InRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const Heading = styled.h2`
  margin-right: 8px;
`;

export const Ml10 = styled.div`
  margin-left: 10px; 
`;

export const HiddenInScreen = styled.p`
  display: none;

  @media print {
    display: block;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
`;

export const MyCard = styled.div`
  border-radius: 18px;
  background-color: #e9edf8;
  padding: 20px;
  flex: 1 0 calc(33.33% - 10px);
  max-width: 300px;
  box-sizing: border-box;
  min-height: 100px; 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out; 

  &:hover {
    transform: scale(1.05);
  }

`;
export const MyCardContent = styled.div`
  width: 100%;
`;
