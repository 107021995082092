import React, { useEffect, useRef, useState } from "react";
import {
  ContainerHome,
  TextDefault,
} from "../../../assets/styles";
import Drawer from "../../../components/Drawer";
import { Grid } from "@mui/material";
import {
  Container,
  FilterByName,
  Heading,
  HiddenInScreen,
  InRow,
  Ml10,
  TabsContainer,
} from "./style";
import Select from "react-select";
import country from "../utils/flag";
import HsCodes from "../utils/hsCodes";
import ButtonBlue from "../../../components/myButton";
import { Button, Col, Image, Row, message } from "antd";
import VazioIcon from "../../../icons/vazio.png";
import data from "../utils/mock";
import { getSessionId, saveCompliance } from "../../../hooks/compliance";
import axios from "axios";
import {
  isoCodeCountryEx,
  isoCodeCountryIm,
} from "../utils/mappedCountryImport";
import { useReactToPrint } from "react-to-print";
import PdfExport from "../utils/pdfExport";
import { authScreen } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import getUserInfo from "../../../hooks/getUsers";
import LoadingPage from "../../../components/LoadingPage";


const tradeOptions = [
  { value: "import", label: "Import" },
  { value: "export", label: "Export" },
];

const mappedHsCodes = HsCodes.map((hsCode) => ({
  value: hsCode.value,
  label: `${hsCode.value} - ${hsCode.hsName}`,
}));

export default function SearchCompliance() {
  const [toggleDrawer, useTroggleDawer] = useState(false);
  const [countryImport, setCountryImport] = useState("");
  const [countryExport, setCountryExport] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [hsCodeSel, setHsCodeSel] = useState("");
  const [trade, setTrade] = useState("");
  const [step, setStep] = useState(0);
  const [key, setKey] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    authScreen().then((res) => {
      if (res) {
        setTimeout(() => {
          setAuth(true);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    });
  });

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (auth) {
      const fetchData = async () => {
        try {
          const userData = await getUserInfo();
          setUserInfo(userData);
        } catch (error) {
          console.error("Erro ao buscar informações do usuário:", error);
        }
      };

      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [auth]);

  function SetToggle(state) {
    useTroggleDawer(state);
    console.log(state);
  }

  function handleCountrySelectImport(selected) {
    setCountryImport(selected);
  }
  function handleCountrySelectExport(selected) {
    setCountryExport(selected);
  }

  function handleCountrySelectOrigin(selected) {
    setCountryOrigin(selected.value);
  }
  function tradeSelect(selected) {
    setTrade(selected.value);
  }
  function handleHsCodeSelect(selected) {
    setHsCodeSel(selected.value);
  }

  const buscar = () => {
    if (!trade || !countryImport || !countryExport || !hsCodeSel) {
      let errorMessage = "Please fill in the following fields:";
      if (!trade) errorMessage += " Trade Direction";
      if (!countryImport) errorMessage += " Country Import";
      if (!countryExport) errorMessage += " Country Export";
      if (!hsCodeSel) errorMessage += " HsCode";

      message.error(errorMessage);
      return;
    }
    //  requestCompliance();
    setStep(1);
  };

  const clearFilters = () => {
    setCountryImport("");
    setCountryExport("");
    setHsCodeSel("");
    setTrade("");
    setCountryOrigin("");
    setKey((prevKey) => prevKey + 1);
    setStep(0);
  };

  useEffect(() => {
    async function fetchSessionId() {
      try {
        const getSession = await getSessionId();
        setSessionId(getSession);
      } catch (error) {
        console.error("Erro ao buscar sessionid:", error);
      }
    }

    fetchSessionId();
  }, []);

  const hsCode = "12011000";

  const requestCompliance = async () => {
    const headers = {
      authority: "preprodcompliance.trademo.com",
      accept: "application/json, text/plain, */*",
      "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
      "content-type": "application/json",
      sssotoken: "fe07e9f4-1406-41c0-acc9-5cc102264678",
      sessionid: sessionId,
    };

    const data = {
      hsCode: hsCode,
      countryOfImport: countryImport?.value,
      countryOfExport: countryExport?.value,
      hsCodeType: 2,
      tradeDirection: trade,
    };

    try {
      const response = await axios.post(
        "https://compliance.trademo.com/trademo/tc/api/v1/getData",
        data,
        {
          headers,
        }
      );

      console.log("Resposta:", response.data);
      return response.data;
    } catch (error) {
      console.error("Erro na solicitação:", error);
      throw error;
    }
  };

  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

  const userId = userInfo?.uid;

  function saveSearch() {
    saveCompliance(userId, data[0]);
  }

  return (
    <>
      {auth ? (
        <ContainerHome>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={toggleDrawer ? 2 : 1}>
              <Drawer handleToggle={SetToggle} initState={toggleDrawer} />
            </Grid>

            <>
              <Grid
                item
                xs={toggleDrawer ? 10 : 11}
                marginLeft={toggleDrawer ? 40 : 20}
                container
              >
                <TextDefault
                  color={"#4b4b4b"}
                  size={"32px"}
                  style={{ marginTop: 20 }}
                >
                  Search Compliance
                </TextDefault>

                <TextDefault
                  color={"#8a97aa"}
                  size={"18px"}
                  bold={"400"}
                  style={{ width: "100%", marginBottom: 15 }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </TextDefault>
                <Container>
                  <TabsContainer>
                    <FilterByName>
                      <Select
                        key={`trade-${key}`}
                        placeholder="Trade Direction"
                        className="basic-select"
                        classNamePrefix="Select a country"
                        name="Trade"
                        // defaultValue={tradeOptions[0]}
                        onChange={tradeSelect}
                        options={tradeOptions}
                        isSearchable
                        formatOptionLabel={(option) => (
                          <div>{option.label}</div>
                        )}
                      />
                      {/* <Select
                    placeholder="Country of origin"
                    className="basic-select"
                    classNamePrefix="Select a country"
                    name="Country"
                    defaultValue={country[0].country}
                    onChange={handleCountrySelectOrigin}
                    value={country.country}
                    options={country}
                    isSearchable
                    formatOptionLabel={(country) => (
                      <Option country={country} />
                    )}
                  /> */}
                      <Select
                        key={`countryImport-${key}`}
                        placeholder="Country of import"
                        className="basic-select"
                        classNamePrefix="Select a country"
                        name="Country"
                        onChange={handleCountrySelectImport}
                        value={country.countryOfImport}
                        options={isoCodeCountryIm.map((country) => ({
                          value: country.isoCode,
                          label: country.countryOfImport,
                        }))}
                        isSearchable
                      />
                      <Select
                        key={`countryExport-${key}`}
                        placeholder="Country of export"
                        className="basic-select"
                        classNamePrefix="Select a country"
                        name="Country"
                        onChange={handleCountrySelectExport}
                        value={country.countryExport}
                        options={isoCodeCountryEx.map((country) => ({
                          value: country.isoCode,
                          label: country.countryOfExport,
                        }))}
                        isSearchable
                      />
                      {/* <Select
                    key={`countryExport-${key}`}
                    placeholder="Country of export"
                    className="basic-select"
                    classNamePrefix="Select a country"
                    name="Country"
                    defaultValue={country[0].country}
                    onChange={handleCountrySelectExport}
                    value={country.country}
                    options={country}
                    isSearchable
                    formatOptionLabel={(country) => (
                      <Option country={country} />
                    )}
                  /> */}
                      <Select
                        key={`hsCodeSel-${key}`}
                        placeholder="Select HsCodes"
                        className="basic-select"
                        classNamePrefix="Hs Codes"
                        name="Hs Codes"
                        onChange={handleHsCodeSelect}
                        options={mappedHsCodes}
                        isSearchable
                        formatOptionLabel={(hsCode) => (
                          <span style={{ fontSize: 14 }}>
                            <strong>{hsCode.value}</strong> -{" "}
                            {hsCode.label.split(" - ")[1]}
                          </span>
                        )}
                      />
                      <ButtonBlue width="100px" onClick={buscar}>
                        Search
                      </ButtonBlue>
                      <Button onClick={clearFilters}>Clear</Button>
                    </FilterByName>
                    <br />
                    <>
                      {step === 0 ? (
                        <>
                          <Row justify="center" align="middle">
                            <Col span={24} style={{ textAlign: "center" }}>
                              <Image
                                preview={false}
                                width={200}
                                src={VazioIcon}
                              />
                              <br />
                              <TextDefault
                                color={"#8a97aa"}
                                size={"18px"}
                                bold={"400"}
                                style={{ marginTop: 20 }}
                              >
                                Your search result will appear here
                              </TextDefault>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <div ref={pdfRef}>
                          <PdfExport action={handlePrint} saveOp={saveSearch} />
                          <HiddenInScreen>
                            Your {trade} compliance survey on the{" "}
                            {formattedDate}
                          </HiddenInScreen>
                          <InRow>
                            <Heading>Country of export:</Heading>
                            <p>{data[0]?.countryOfExport}</p>

                            <Ml10>
                              <Heading>Country of import:</Heading>
                            </Ml10>
                            <p>{data[0]?.countryOfImport}</p>
                          </InRow>
                          <hr />
                          <InRow>
                            <Heading>Reference:</Heading>
                            <p>{data[0]?.reference}</p>
                            <Ml10>
                              <Heading>Derived Reference:</Heading>
                            </Ml10>
                            <p>{data[0]?.derivedReference}</p>
                          </InRow>
                          <hr />
                          <InRow>
                            <Heading>Controlling authority:</Heading>
                            <p>{data[0]?.controllingAuthority}</p>
                            <Ml10>
                              <Heading>Scope of control:</Heading>
                            </Ml10>

                            <p>{data[0]?.scopeOfControl}</p>
                          </InRow>

                          <hr />

                          <h2>Notes</h2>
                          <p>{data[0]?.notes}</p>

                          <h2>Exceptions</h2>
                          <p>{data[0]?.exceptions}</p>
                        </div>
                      )}
                    </>
                  </TabsContainer>
                </Container>
              </Grid>
            </>
          </Grid>
        </ContainerHome>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
