import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import country from "../../../../components/Flag";
import Capitalize from "../../../../utils/capitalize";
import { ActionButton } from "./styles";

//Modelo de teste para tabela

export default function DataGridDemo({ ...props }) {
  const columns = [
    {
      field: "country",
      headerName: "Country",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt={params.row.country}
            src={renderFlag(params.row.country)}
            style={{ width: 40, height: 40, marginLeft: 10 }}
          />
          <p style={{ marginLeft: 10 }}>{params.row.country}</p>
        </div>
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 350,
      editable: false,
      valueGetter: (params) => `${Capitalize(params.row.companyName)}`,
    },
    {
      field: "location",
      headerName: "Location",
      width: 350,
      editable: false,
      valueGetter: (params) =>
        `${params.row.addressList ? params.row.addressList : ""}`,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ActionButton
            onClick={() => props.clickRow(params.row.id, props.data)}
          >
            Profile
          </ActionButton>

          <ActionButton onClick={() => console.log(params.row)}>
            Compliance
          </ActionButton>
        </div>
      ),
    },
  ];
  function formatMoney(amount) {
    // Formata o número com duas casas decimais
    const formattedAmount = parseFloat(amount).toFixed(2);
    // Adiciona o símbolo do dólar na frente do número
    let result =
      "US$ " + formattedAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // Adiciona o sufixo 'K' para números maiores que 1 mil e menor que 1 milhão
    if (amount >= 1000 && amount < 1000000) {
      result = result.replace(/\.\d+/, "") + "K";
    }
    // Adiciona o sufixo 'M' para números maiores que 1 milhão e menor que 1 bilhão
    else if (amount >= 1000000 && amount < 1000000000) {
      result = result.replace(/\.\d+/, "") + "M";
    }
    // Adiciona o sufixo 'B' para números maiores que 1 bilhão
    else if (amount >= 1000000000) {
      result = result.replace(/\.\d+/, "") + "B";
    }
    return result;
  }

  const renderFlag = (countryName) => {
    const filterCountry = country.filter(
      (obj) => obj.country.toLocaleLowerCase() == countryName
    );
    return filterCountry[0].src;
  };
  function getCustomRowId(row) {
    return row.companyId + Math.random();
  }

  return (
    <Box sx={{ height: "100%", width: "98%" }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        // onRowSelectionModelChange={setSelctionRow}
        getRowId={getCustomRowId}
      />
    </Box>
  );
}
